import { FC } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { WorksheetData } from "../../api";
import { GridModelViewer } from "../GridModelViewer";
import { FirstDataRenderedEvent } from "ag-grid-community";

type WorksheetViewerProps = {
    worksheetData: WorksheetData[];
};

export const WorksheetViewer: FC<WorksheetViewerProps> = ({ worksheetData }) => {
    if (worksheetData.length === 0) {
        return "No Data Found";
    } else if (worksheetData.length === 1) {
        return <GridModelViewer gridModel={worksheetData[0]} fillVerticalSpace={true} />;
    } else if (worksheetData.length > 1) {
        return (
            <Tabs isLazy>
                <TabList>
                    {worksheetData.map((worksheet, index) => (
                        <Tab key={index}>{worksheet.name}</Tab>
                    ))}
                </TabList>

                <TabPanels>
                    {worksheetData.map((worksheet, index) => (
                        <TabPanel key={index}>
                            {index === 0 ? (
                                <GridModelViewer
                                    onFirstDataRendered={(firstDataRenderedEvent: FirstDataRenderedEvent) => {
                                        firstDataRenderedEvent.api.autoSizeAllColumns();
                                        setTimeout(() => {
                                            firstDataRenderedEvent.api.autoSizeAllColumns();
                                        }, 200);
                                    }}
                                    gridModel={worksheet}
                                    fillVerticalSpace={true}
                                />
                            ) : (
                                <GridModelViewer gridModel={worksheet} fillVerticalSpace={true} />
                            )}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        );
    }
};
