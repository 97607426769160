import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    FormControl,
    HStack,
    ModalBody,
    Text,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
} from "@chakra-ui/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AgGrid, formatTimestampStr, ModalForResultContent } from "am-tax-fe-core";
import { GetCalculationRunsForImmediateChildrenArgs, useCalculationRunsForImmediateChildren } from "../../api";
import { ICellRendererParams } from "ag-grid-community";
import { useMemo } from "react";

type Form = {
    childEntityIds: string[];
};

export const SelectChildEntitiesForm: ModalForResultContent<GetCalculationRunsForImmediateChildrenArgs, Form> = ({ inputArgs, onResult, close }) => {
    const calculationRunsQuery = useCalculationRunsForImmediateChildren(inputArgs);
    const calculationRuns = calculationRunsQuery.data;

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<Form>();

    const onSubmit: SubmitHandler<Form> = async data => {
        onResult(data);
    };

    const allIds = useMemo(() => (calculationRuns || []).map(calculationRun => calculationRun.entityId), [calculationRuns]);

    const allChecked = () => {
        return allIds.length === getValues().childEntityIds?.length;
    };

    const someChecked = () => {
        return getValues().childEntityIds?.length > 0 && getValues().childEntityIds?.length < allIds.length;
    };

    const noneChecked = () => {
        return !getValues().childEntityIds || getValues().childEntityIds?.length === 0;
    };

    return (
        <ModalContent>
            <ModalHeader>Entities to Include in Calculation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction={"column"} gap={"2rem"}>
                        <FormControl isInvalid={!!errors.childEntityIds}>
                            <Controller
                                name="childEntityIds"
                                control={control}
                                render={({ field }) => (
                                    <Flex direction={"column"} gap={"1rem"}>
                                        <Box ml={"1rem"}>
                                            <Checkbox
                                                isChecked={allChecked()}
                                                isIndeterminate={someChecked()}
                                                onChange={() => (someChecked() || noneChecked() ? field.onChange(allIds) : field.onChange([]))}
                                            >
                                                Select All
                                            </Checkbox>
                                        </Box>
                                        <CheckboxGroup value={field.value || []} onChange={values => field.onChange(values)}>
                                            <AgGrid
                                                rowData={calculationRuns}
                                                onRowClicked={({ data: calculationResponse }) => {
                                                    const childEntityIds = getValues().childEntityIds || [];
                                                    if (childEntityIds.includes(calculationResponse!.entityId)) {
                                                        field.onChange(childEntityIds.filter(id => id !== calculationResponse!.entityId));
                                                    } else {
                                                        field.onChange([...childEntityIds, calculationResponse!.entityId]);
                                                    }
                                                }}
                                                columnDefs={[
                                                    {
                                                        sortable: false,
                                                        filter: false,
                                                        headerName: "Underlying Entity",
                                                        field: "entityName",
                                                        cellRenderer: ({ data: calculationResponse }: ICellRendererParams) =>
                                                            calculationResponse.entityName !== null ? (
                                                                <Checkbox value={String(calculationResponse.entityId)} onClick={e => e.preventDefault()}>
                                                                    {calculationResponse.entityName}
                                                                </Checkbox>
                                                            ) : (
                                                                <Text>{calculationResponse.entityName}</Text>
                                                            ),
                                                    },
                                                    {
                                                        headerName: "Last Calculation",
                                                        field: "endDate",
                                                        sort: "desc",
                                                        cellRenderer: ({ data: calculationResponse }: ICellRendererParams) =>
                                                            calculationResponse!.acceptedBy != null
                                                                ? formatTimestampStr(calculationResponse!.endDate?.toString())
                                                                : "Not yet run",
                                                    },
                                                    {
                                                        headerName: "Last Accepted",
                                                        field: "acceptedDate",
                                                        cellRenderer: ({ data: calculationResponse }: ICellRendererParams) =>
                                                            calculationResponse!.acceptedBy != null
                                                                ? formatTimestampStr(calculationResponse!.acceptedDate?.toString())
                                                                : "Not yet run",
                                                    },
                                                    {
                                                        headerName: "Accepted By",
                                                        field: "acceptedBy",
                                                        valueGetter: ({ data: calculationResponse }) => `${calculationResponse!.acceptedBy?.firstName || ""} ${calculationResponse?.acceptedBy?.lastName || ""}`
                                                    }
                                                ]}
                                            />
                                        </CheckboxGroup>
                                    </Flex>
                                )}
                            />
                        </FormControl>
                        <HStack justify={"space-between"}>
                            <Button
                                variant={"ghost"}
                                onClick={() => {
                                    close();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant={"primary"}>
                                Continue
                            </Button>
                        </HStack>
                    </Flex>
                </form>
            </ModalBody>
        </ModalContent>
    );
};
