import { FC, useCallback, useEffect, useRef } from "react";
import cytoscape, { Core, ElementDefinition, Position, Stylesheet } from "cytoscape";
import { Box, BoxProps, Flex, Icon, IconButton } from "@chakra-ui/react";
import { theme } from "am-tax-fe-core";
import { EntityHierarchy, EntityNodeModel } from "../../api";
import { EntityType } from "../../enums/EntityType";
import { IconArrowsMaximize, IconLine, IconMinus, IconPlus } from "@tabler/icons-react";
import { useAtom } from "jotai";
import { isHierarchyTaxiLinesAtom } from "../../atoms";
import { saveIsHierarchyTaxiLinesPref } from "../../utils/localStorageUtils";

type EntityNode = ElementDefinition & { data: { isForeign?: string; isActive?: string; sourceLabel?: string; targetLabel?: string } };

type EntityHierarchyProps = {
    entityHierarchy?: EntityHierarchy;
    useMiniStyles?: boolean;
    onExpand?: () => void;
    selectedEntityId?: string;
    onEntityClicked?: (entityId: string) => void;
} & BoxProps;

export const EntityHierarchyViewer: FC<EntityHierarchyProps> = ({
    entityHierarchy,
    useMiniStyles,
    onExpand,
    selectedEntityId,
    onEntityClicked,
    ...boxProps
}) => {
    const [isHierarchyTaxiLines, setIsHierarchyTaxiLinesAtom] = useAtom(isHierarchyTaxiLinesAtom);
    const setIsHierarchyTaxiLines = (value: boolean) => {
        saveIsHierarchyTaxiLinesPref(value);
        setIsHierarchyTaxiLinesAtom(value);
    };

    const containerRef = useRef<HTMLDivElement>(null);
    // const numberOfEdgesTargetingEachNode = useMemo(() => {
    //     const histogram: Record<string, number> = {};
    //     if (entityHierarchy) {
    //         for (const r of entityHierarchy.relationships) {
    //             histogram[r.childEntityId] = (histogram[r.childEntityId] || 0) + 1;
    //         }
    //     }
    //     return histogram;
    // }, [entityHierarchy]);

    const getElements = useCallback(() => {
        if (!entityHierarchy) {
            return [];
        }

        return [
            /* NODES */
            ...entityHierarchy.entities.map((entity): ElementDefinition => {
                const node: EntityNode = {
                    data: {
                        id: entity.entityId,
                        label: entity.entityName,
                    },
                } as unknown as EntityNode;

                setNodeShape(entity, node);

                if (entity.isForeign) {
                    node.data.isForeign = "true";
                }

                if (entity.entityId === selectedEntityId) {
                    node.data.isActive = "true";
                }

                return node;
            }),
            /* EDGES */
            ...entityHierarchy.relationships.map((relationship): ElementDefinition => {
                const edge: EntityNode = {
                    data: {
                        id: relationship.relationshipId,
                        source: relationship.parentEntityId,
                        target: relationship.childEntityId,
                    },
                    classes: isHierarchyTaxiLines ? ["taxi"] : [],
                } as unknown as EntityNode;
                //if (relationship.ownershipPercentage) {
                //    // if there is more than one targeting the same node, then we want to show the label on the source so that it doesn't overlap with other labels
                //    if (numberOfEdgesTargetingEachNode[relationship.childEntityId] > 1) {
                //        edge.data.sourceLabel = `${relationship.ownershipPercentage}`;
                //    } else {
                //        edge.data.targetLabel = `${relationship.ownershipPercentage}`;
                //    }
                //}
                return edge;
            }),
        ];
    }, [entityHierarchy, selectedEntityId, isHierarchyTaxiLines]);

    const cyRef = useRef<Core>();

    useEffect(() => {
        if (containerRef.current && entityHierarchy) {
            const cy = cytoscape({
                container: containerRef.current,
                style: getStyles(!!useMiniStyles),
                layout: getLayout(!!useMiniStyles),
                elements: getElements(),
                userZoomingEnabled: false,
            });

            containerRef.current.addEventListener("wheel", e => {
                e.preventDefault();
                e.stopPropagation();
                if (e.deltaY < 0) {
                    panBy(cyRef.current, { x: 0, y: 50 });
                } else {
                    panBy(cyRef.current, { x: 0, y: -50 });
                }
                e.stopImmediatePropagation();
                return false;
            });

            setZoomOptions(cy, selectedEntityId, !!useMiniStyles);

            cy.on("tap", "node", function (evt) {
                if (onEntityClicked) {
                    onEntityClicked(evt.target.id());
                }
            });

            cyRef.current = cy;

            return () => {
                cy.destroy();
            };
        }
    }, [entityHierarchy, getElements, onEntityClicked]); // intentionally leaving out selectedEntityId and useMiniStyles because they are handled in the next useEffect

    useEffect(() => {
        if (cyRef.current) {
            cyRef.current.data({ layout: getLayout(!!useMiniStyles) });
            cyRef.current.data({ style: getStyles(!!useMiniStyles) });
            setZoomOptions(cyRef.current, selectedEntityId, !!useMiniStyles);
        }
    }, [selectedEntityId, useMiniStyles]);

    return (
        <Box position={"relative"} {...boxProps}>
            <Box ref={containerRef} w={"100%"} h={"100%"} />
            <Flex direction={"column"} position={"absolute"} bottom={0} right={0} p={".5rem .75rem"} gap={".75rem"}>
                <IconButton
                    aria-label={"taxi lines toggle  "}
                    icon={<Icon as={IconLine} />}
                    variant={"naked"}
                    color={isHierarchyTaxiLines ? "unset" : "white"}
                    bg={isHierarchyTaxiLines ? "unset" : "blue.800"}
                    _hover={{ bg: isHierarchyTaxiLines ? "unset" : "blue.600" }}
                    onClick={() => setIsHierarchyTaxiLines(!isHierarchyTaxiLines)}
                    p={".25rem"}
                />
                <IconButton aria-label={"zoom in"} icon={<Icon as={IconPlus} />} variant={"naked"} onClick={() => zoomIn(cyRef.current)} />
                <IconButton aria-label={"zoom in"} icon={<Icon as={IconMinus} />} variant={"naked"} onClick={() => zoomOut(cyRef.current)} />
                {onExpand && <IconButton aria-label={"expand"} icon={<Icon as={IconArrowsMaximize} />} variant={"naked"} onClick={onExpand} />}
            </Flex>
        </Box>
    );
};

const zoomIncrement = 0.15;

const panBy = (cy: Core | undefined, renderedPosition: Position): void => {
    if (cy) {
        cy.panBy(renderedPosition);
    }
};
const zoom = (cy: Core | undefined, inward: boolean): void => {
    if (cy) {
        const { x1, x2, y1, y2 } = cy.extent();
        cy.zoom({ level: cy.zoom() + (inward ? zoomIncrement : -zoomIncrement), position: { x: (x1 + x2) / 2, y: (y1 + y2) / 2 } });
    }
};
const zoomIn = (cy: Core | undefined): void => {
    zoom(cy, true);
};
const zoomOut = (cy: Core | undefined): void => {
    zoom(cy, false);
};
const setZoomOptions = (cy: Core, selectedEntityId: string | undefined, miniStyles: boolean): void => {
    cy.minZoom(0.3);
    cy.maxZoom(2.5);
    cy.zoom();

    if (selectedEntityId) {
        cy.zoom({
            level: miniStyles ? 0.6 : 0.75,
            position: cy.getElementById(selectedEntityId).position(),
        });
    } else {
        cy.zoom({
            level: miniStyles ? 0.35 : 0.75,
            position: { x: cy.width() / 2, y: 300 },
        });
    }
};

const getLayout = (miniStyles: boolean) => {
    return {
        name: "breadthfirst",
        directed: true,
        spacingFactor: miniStyles ? 1.2 : 1.2,
    };
};

const getStyles = (miniStyles: boolean): Stylesheet[] => {
    return [
        {
            selector: "node",
            style: {
                // @ts-expect-error - cytoscape types are not up to date. Their example code says that this is legal.
                shape: "data(type)",
                height: miniStyles ? "75px" : "150px",
                width: miniStyles ? "125px" : "250px",
                "border-style": "solid",
                "border-color": theme.colors.gray[400],
                "border-width": 1,
                "background-color": theme.colors.gray[50],
                "font-size": miniStyles ? "18px" : "17px",
                color: theme.colors.gray[800],
                "font-weight": 400,
                "text-outline-color": miniStyles ? "white" : "",
                "text-outline-width": miniStyles ? "2px" : 0,
                "text-outline-opacity": 0.5,
            },
        },
        {
            selector: "node[points]",
            style: {
                "shape-polygon-points": "data(points)",
            },
        },
        {
            selector: 'node[isActive="true"]',
            style: {
                color: "black",
                "font-weight": 600,
                "border-width": 3,
                "border-color": "black",
            },
        },
        {
            selector: 'node[isForeign="true"]',
            style: {
                "background-color": theme.colors.blue[100],
            },
        },
        {
            selector: "node[label]",
            style: {
                label: "data(label)",
                "text-wrap": "wrap",
                "text-overflow-wrap": "whitespace",
                "text-max-width": "180px",
                "text-valign": "center",
                "text-halign": "center",
            },
        },
        {
            selector: 'node[type="triangle"]',
            style: {
                "text-valign": "bottom",
                "text-margin-y": miniStyles ? -55 : -65,
                "text-max-width": "150px",
            },
        },
        {
            selector: "node.triangle-bg",
            style: {
                "text-valign": "bottom",
                "text-margin-y": miniStyles ? -55 : -65,
                "text-max-width": "150px",
                "background-image":
                    "data:image/svg+xml;utf8," +
                    encodeURIComponent(
                        `<svg width="100px" height="100px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><polygon points=".5,99.5 50,.5 99.5,99.5" stroke="${theme.colors.gray[400]}" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round" fill="none"/></svg>`,
                    ),
                "background-width": "100%",
                "background-height": "100%",
            },
        },
        {
            selector: "node.upside-down-triangle-bg",
            style: {
                height: miniStyles ? "75px" : "150px",
                "text-valign": "top",
                "text-margin-y": miniStyles ? 55 : 65,
                "text-max-width": "150px",
                "background-image":
                    "data:image/svg+xml;utf8," +
                    encodeURIComponent(
                        `<svg width="100px" height="100px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><polygon points=".5,.5 50,99.5 99.5,.5" stroke="${theme.colors.gray[400]}" stroke-width=".5" stroke-linecap="round" stroke-linejoin="round" fill="none"/></svg>`,
                    ),
                "background-width": "100%",
                "background-height": "100%",
            },
        },
        {
            selector: "node.ellipse-bg",
            style: {
                "background-image":
                    "data:image/svg+xml;utf8," +
                    encodeURIComponent(
                        `<svg width="250px" height="250px" viewBox="2.75 2.75 18.5 18.5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="${theme.colors.gray[400]}" stroke-width=".15" stroke-linecap="round" stroke-linejoin="round"></path></svg>`,
                    ),
                "background-width": "100%",
                "background-height": "100%",
            },
        },
        {
            selector: "node.cfc-bg",
            style: {
                "text-margin-y": miniStyles ? -10 : -15,
                "background-image":
                    "data:image/svg+xml;utf8," +
                    encodeURIComponent(
                        `<svg width="250px" height="100px" viewBox="0 0 250 100" xmlns="http://www.w3.org/2000/svg"><text x="95" y="90" font-size="30px" font-weight="600" font-family="Arial" fill="${theme.colors.gray[600]}">CFC</text></svg>`,
                    ),
                "background-width": "100%",
                "background-height": "100%",
            },
        },
        {
            selector: "edge",
            style: {
                "text-outline-color": "white",
                "text-outline-width": "4px",
                width: "1px",
            },
        },
        {
            selector: "edge[sourceLabel]",
            style: {
                "source-label": "data(sourceLabel)",
                "source-text-offset": 30,
            },
        },
        {
            selector: "edge[targetLabel]",
            style: {
                "target-label": "data(targetLabel)",
                "target-text-offset": miniStyles ? 20 : 30,
            },
        },
        {
            selector: "edge.taxi",
            style: {
                "curve-style": "taxi",
                "taxi-direction": "downward",
                "taxi-turn": miniStyles ? 40 : 70,
                "taxi-turn-min-distance": miniStyles ? 15 : 25,
            },
        },
        {
            selector: "edge.unbundled-bezier",
            style: {
                "curve-style": "unbundled-bezier",
                "control-point-distances": 20,
                "control-point-weights": 0.1,
            },
        },
        {
            selector: "edge.dashed",
            style: {
                "line-style": "dashed",
            },
        },
    ];
};

const setNodeShape = (entity: EntityNodeModel, node: EntityNode) => {
    switch (entity.entityType) {
        case EntityType.Corporation:
            node.data.type = "rectangle";
            break;
        case EntityType.Partnership:
            node.data.type = "triangle";
            break;
        case EntityType.Branch:
            node.data.type = "ellipse";
            break;
        case EntityType.Individual:
        case EntityType.Estate:
            node.data.type = "ellipse";
            break;
        case EntityType.ReverseHybridCorporation:
            node.data.type = "rectangle";
            node.classes = ["upside-down-triangle-bg"];
            break;
        case EntityType.DisregardedEntity:
        case EntityType.HybridBranch:
            node.data.type = "rectangle";
            node.classes = ["ellipse-bg"];
            break;
        case EntityType.Trust:
            node.data.type = "diamond";
            break;
        case EntityType.ControlledForeignCorporation:
            node.data.type = "rectangle";
            node.classes = ["cfc-bg"];
            break;
        case EntityType.Asset:
            node.data.type = "hexagon";
            break;
        case EntityType.SCorporation:
            node.data.type = "rectangle";
            node.classes = ["triangle-bg"];
            break;
        /*
         * Random shapes can be created by specifying a type=polygon and then providing points.
         * This is an example of a trapezoid shape:
         * node.data.type = "polygon";
         * node.data.points = [-1, 1, -0.9, -1, 0.9, -1, 1, 1];
         */
    }
};
