import { FunctionComponent } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useImportSummary, useWorksheetDataByImportSummaryId } from "../../api";

import { WorksheetViewer } from "../../components/WorksheetViewer";

export const ViewImportDataModal: FunctionComponent = () => {
    const navigate = useNavigate();

    const { clientId, importSummaryId } = useParams();

    const importSummaryQuery = useImportSummary({ clientId, importSummaryId });
    const importSummary = importSummaryQuery.data || null;

    const worksheetDataQuery = useWorksheetDataByImportSummaryId({ clientId, importSummaryId });
    const worksheetData = worksheetDataQuery.data || null;

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                navigate(`..`);
            }}
            size={"8xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{importSummary?.fileName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{worksheetData && <WorksheetViewer worksheetData={worksheetData} />}</ModalBody>
            </ModalContent>
        </Modal>
    );
};
