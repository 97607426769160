import { FC, useMemo } from "react";
import { ColumnDefinition, WorksheetData } from "../../api";
import { ColDef } from "ag-grid-community";
import { AgGrid, AgGridProps, formatCurrency, formatNumber, formatPercentage, NumberFormatOptions, CurrencyFormatOptions } from "am-tax-fe-core";
import { useNavigate } from "react-router-dom";
import { removeNulls } from "../../utils";
import { ColGroupDef } from "ag-grid-community/dist/lib/entities/colDef";

type GridModelViewerProps = {
    gridModel: WorksheetData;
} & Omit<AgGridProps, "rowData" | "columnDefs" | "navigate" | "autoSizeStrategy">;

export const GridModelViewer: FC<GridModelViewerProps> = ({ gridModel, ...gridProps }) => {
    const navigate = useNavigate();
    const columnDefs = useMemo(() => gridModel.columns.map(mapColumnDefinition), [gridModel.columns]);
    return (
        <AgGrid
            container={{
                sx: {
                    ".ag-col-grouping-header:after": {
                        content: '""',
                        zIndex: "1",
                        bg: "blue.600",
                        position: "absolute",
                        bottom: "-1px",
                        left: "var(--ag-cell-horizontal-padding)",
                        right: "var(--ag-cell-horizontal-padding)",
                        h: "2px",
                    },
                },
            }}
            autoSizeStrategy={{ type: "fitCellContents" }}
            rowData={gridModel.rowData}
            columnDefs={columnDefs}
            navigate={gridModel.navigateTo ? { to: gridModel.navigateTo, navigateFunction: navigate } : undefined}
            {...gridProps}
        />
    );
};

const mapColumnDefinition = (column: ColumnDefinition) => {
    // create a copy that has only the non-null values from the column definition because null values cause ag-grid to err
    const columnDefinition = removeNulls(column);
    const { numberFormatOptions, children, ...columnAttributes } = columnDefinition;
    const colDef = columnAttributes as ColDef;

    // Highlight cell red if it contains 'error'
    colDef.cellStyle = params => {
        if (params.value && params.value.toLowerCase().includes("error")) {
            return { color: "red" };
        }
        return null;
    };

    // if the column has numberFormatOptions and a cellDataType of number, currency, or percentage, add a valueFormatter that applies the numberFormatOptions
    if (
        numberFormatOptions &&
        Object.keys(numberFormatOptions).length > 0 &&
        columnDefinition.cellDataType &&
        ["number", "currency", "percentage"].includes(columnDefinition.cellDataType)
    ) {
        switch (colDef.cellDataType) {
            case "number":
                colDef.valueFormatter = params => formatNumber(params.value, numberFormatOptions as NumberFormatOptions);
                break;
            case "currency":
                colDef.valueFormatter = params => formatCurrency(params.value, "USD", numberFormatOptions as CurrencyFormatOptions);
                break;
            case "percentage":
                colDef.valueFormatter = params => formatPercentage(params.value, numberFormatOptions as NumberFormatOptions);
                break;
        }
    }

    // if the column has children, recursively map them
    if (children) {
        const colGroupDef = colDef as unknown as ColGroupDef;

        colGroupDef.children = children.map(mapColumnDefinition);
        colGroupDef.marryChildren = true;
        colGroupDef.headerClass = "ag-col-grouping-header";
    }

    return colDef;
};
