import { useMatch } from "react-router-dom";
import { useClient } from "../api";

export const useClientFromParams = () => {
    const matchesClientSpace = useMatch("/client/:clientId/*");
    const matchesClientAdminSpace = useMatch("/clientAdmin/:clientId/*");
    const clientId = matchesClientSpace?.params?.clientId || matchesClientAdminSpace?.params?.clientId;
    const clientQuery = useClient(clientId);
    return { clientId, clientQuery };
};
