import { FunctionComponent, useCallback } from "react";
import { Page } from "../Page";
import { useParams } from "react-router-dom";
import { downloadClientImportTemplateWithData, downloadClientImportTemplate, useClientImportSummaries, useUploadClientDataFile } from "../../api";
import { DownloadFunction, FileContainer, paramToNum } from "am-tax-fe-core";
import { DataImportsGrid } from "../../components/DataImportsGrid";
import { DataImportControls } from "../../components/DataImportControls";

import { displayImportType, ImportStatus, ImportType } from "../../enums";

export const ClientDataImportsPage: FunctionComponent = () => {
    const { clientId, importType: importTypeParam } = useParams();
    const importType = paramToNum(importTypeParam) as ImportType;

    const importSummaryQuery = useClientImportSummaries({ clientId, importType });

    const uploadClientDataFile = useUploadClientDataFile();

    const downloadTemplateFn: DownloadFunction = useCallback(
        async progressCallback =>
            downloadClientImportTemplate({
                clientId: clientId!,
                importType,
                progressCallback,
            }),
        [clientId, importType],
    );

    const downloadDataFn: DownloadFunction = useCallback(
        async progressCallback =>
            downloadClientImportTemplateWithData({
                clientId: clientId!,
                importType,
                progressCallback,
            }),
        [clientId, importType],
    );

    const onFileAdded = useCallback(
        async (fileContainer: FileContainer) =>
            uploadClientDataFile.mutateAsync({
                clientId: clientId!,
                importType,
                fileContainer,
            }),
        [clientId, importType, uploadClientDataFile],
    );

    if (!clientId || !importType) {
        return "This page requires clientId and importType to render.";
    }

    return (
        <Page title={`Import ${displayImportType(importType)}`}>
            {clientId && (
                <DataImportsGrid
                    above={
                        <DataImportControls
                            hasData={!!importSummaryQuery.data && importSummaryQuery.data.some(importSummary => importSummary.status == ImportStatus.Accepted)}
                            downloadTemplateFn={downloadTemplateFn}
                            downloadDataFn={downloadDataFn}
                            onFileAdded={onFileAdded}
                        />
                    }
                    fillVerticalSpace
                    spacing={0}
                    clientId={clientId}
                    importType={importType}
                    data={importSummaryQuery.data}
                    isLoading={importSummaryQuery.isFetching}
                />
            )}
        </Page>
    );
};
