import { Fetcher, Middleware } from "openapi-typescript-fetch";

import { components, paths } from "./generated/generated-api";
import { applyParams, downloadFileAsBlob, DownloadFunctionResult, process, FileContainer, saveConfiguration, uploadFile } from "am-tax-fe-core";
import { DownloadProgressCallback } from "am-tax-fe-core/dist/util/apiUtils";

// declare fetcher for paths
const fetcher = Fetcher.for<paths>();

export function configure(baseUrl: string, msalMiddleware: Middleware[]) {
    saveConfiguration(fetcher, baseUrl, msalMiddleware);
}

export const getClientKPIs = async (args: GetClientKPIsArgs): Promise<KPISummary[]> => process(_getClientKPIs(args));
const getClientKPIsPath = "/api/client/{clientId}/kpi";
export type GetClientKPIsArgs = paths[typeof getClientKPIsPath]["get"]["parameters"]["path"];
const _getClientKPIs = fetcher.path(getClientKPIsPath).method("get").create();

// Client
export const getClients = async (): Promise<Client[]> => process(_getClients({}));
export type Client = components["schemas"]["ClientResponse"];
const _getClients = fetcher.path("/api/clients").method("get").create();

export const getClient = async (clientId: string): Promise<Client> => process(_getClient({ clientId }));
const _getClient = fetcher.path("/api/clients/{clientId}").method("get").create();

export const createClient = async (args: ClientRequest): Promise<Client> => process(_createClient(args));
export type ClientRequest = components["schemas"]["ClientRequest"];
const _createClient = fetcher.path("/api/clients").method("post").create();

export const getClientConfiguration = async (args: GetClientConfigurationArgs): Promise<ClientConfiguration> => process(_getClientConfiguration(args));
export type ClientConfiguration = components["schemas"]["ClientConfiguration"];
const clientConfigurationPath = "/api/clients/{clientId}/configuration";
export type GetClientConfigurationArgs = paths[typeof clientConfigurationPath]["get"]["parameters"]["path"];
const _getClientConfiguration = fetcher.path(clientConfigurationPath).method("get").create();

export const saveClientConfiguration = async (args: SaveClientConfigurationArgs): Promise<ClientConfiguration> => process(_saveClientConfiguration(args));
export type SaveClientConfigurationArgs = paths[typeof clientConfigurationPath]["put"]["parameters"]["path"] & ClientConfiguration;
const _saveClientConfiguration = fetcher.path(clientConfigurationPath).method("put").create();

export const getEntities = async (clientId: string): Promise<Entity[]> => process(_getEntities({ clientId }));
export type Entity = components["schemas"]["EntityResponse"];
const _getEntities = fetcher.path("/api/clients/{clientId}/entities").method("get").create();

export const getEntity = async (args: GetEntityArgs): Promise<Entity> => process(_getEntity(args));
const getEntityPath = "/api/clients/{clientId}/entities/{entityId}";
export type GetEntityArgs = paths[typeof getEntityPath]["get"]["parameters"]["path"];
const _getEntity = fetcher.path(getEntityPath).method("get").create();

export const getEntitySummary = async (args: GetEntitySummaryArgs): Promise<EntitySummary> => process(_getEntitySummary(args));
export type EntitySummary = components["schemas"]["EntitySummaryResponse"];
export type ImportTypeResponse = components["schemas"]["ImportTypeResponse"];
const getEntitySummaryPath = "/api/client/{clientId}/entity/{entityId}/summary";
export type GetEntitySummaryArgs = paths[typeof getEntitySummaryPath]["get"]["parameters"]["path"];
const _getEntitySummary = fetcher.path(getEntitySummaryPath).method("get").create();

export const getEntityKPIs = async (args: GetEntityKPIsArgs): Promise<KPISummary[]> => process(_getEntityKPIs(args));
export type KPISummary = components["schemas"]["KPISummaryResponse"];
const getEntityKPIsPath = "/api/client/{clientId}/entity/{entityId}/kpi";
export type GetEntityKPIsArgs = paths[typeof getEntityKPIsPath]["get"]["parameters"]["path"];
const _getEntityKPIs = fetcher.path(getEntityKPIsPath).method("get").create();

export const getEntityHierarchy = async (args: GetEntityHierarchyArgs): Promise<EntityHierarchy> => process(_getEntityHierarchy(args));
export type EntityHierarchy = components["schemas"]["EntityHierarchyResponse"];
export type EntityNodeModel = components["schemas"]["EntityNodeModel"];
const getEntityHierarchyPath = "/api/client/{clientId}/entityHierarchy";
export type GetEntityHierarchyArgs = paths[typeof getEntityHierarchyPath]["get"]["parameters"]["path"];
const _getEntityHierarchy = fetcher.path(getEntityHierarchyPath).method("get").create();

export const getEntityIncome = async (args: GetEntityIncomeArgs): Promise<EntityIncome> => process(_getEntityIncome(args));
export type EntityIncome = components["schemas"]["IncomeSummaryResponse"];
const getEntityIncomePath = "/api/client/{clientId}/entity/{entityId}/income";
export type GetEntityIncomeArgs = paths[typeof getEntityIncomePath]["get"]["parameters"]["path"];
const _getEntityIncome = fetcher.path(getEntityIncomePath).method("get").create();

export const getImportSummaries = async (args: GetImportSummariesArgs): Promise<ImportSummary[]> => process(_getImportSummaries(args));
export type ImportSummary = components["schemas"]["ImportSummaryResponse"];
const getImportSummariesPath = "/api/client/{clientId}/entity/{entityId}/importType/{importType}/importsummaries";
export type GetImportSummariesArgs = paths[typeof getImportSummariesPath]["get"]["parameters"]["path"];
const _getImportSummaries = fetcher.path(getImportSummariesPath).method("get").create();

export const getImportSummary = async (args: GetImportSummaryArgs): Promise<ImportSummary> => process(_getImportSummary(args));
const getImportSummaryPath = "/api/client/{clientId}/importsummary/{importSummaryId}";
export type GetImportSummaryArgs = paths[typeof getImportSummaryPath]["get"]["parameters"]["path"];
const _getImportSummary = fetcher.path(getImportSummaryPath).method("get").create();

export const deleteImportSummary = async (args: DeleteImportSummaryArgs): Promise<unknown> => process(_deleteImportSummary(args));
const deleteImportSummaryPath = "/api/client/{clientId}/importsummary/{importSummaryId}";
export type DeleteImportSummaryArgs = paths[typeof deleteImportSummaryPath]["delete"]["parameters"]["path"];
const _deleteImportSummary = fetcher.path(deleteImportSummaryPath).method("delete").create();

export const getWorksheetDataByImportSummaryId = async (args: GetWorksheetDataArgs): Promise<WorksheetData[]> => process(_getWorksheetData(args));
export type WorksheetData = components["schemas"]["WorksheetModel"];
export type ColumnDefinition = components["schemas"]["ColumnDefinition"];
const getWorksheetDataPath = "/api/client/{clientId}/importSummary/{importSummaryId}/worksheetData";
export type GetWorksheetDataArgs = paths[typeof getWorksheetDataPath]["get"]["parameters"]["path"];
const _getWorksheetData = fetcher.path(getWorksheetDataPath).method("get").create();

export const getClientDataByImportType = async (args: GetClientDataByImportTypeArgs): Promise<WorksheetData[]> => process(_getClientDataByImportType(args));
const getClientDataByImportTypePath = "/api/client/{clientId}/importtype/{importType}/worksheetData";
export type GetClientDataByImportTypeArgs = paths[typeof getClientDataByImportTypePath]["get"]["parameters"]["path"];
const _getClientDataByImportType = fetcher.path(getClientDataByImportTypePath).method("get").create();

export const getEntityDataByImportType = async (args: GetEntityDataByImportTypeArgs): Promise<WorksheetData[]> => process(_getEntityDataByImportType(args));
const getEntityDataByImportTypePath = "/api/client/{clientId}/entity/{entityId}/importtype/{importType}/worksheetData";
export type GetEntityDataByImportTypeArgs = paths[typeof getEntityDataByImportTypePath]["get"]["parameters"]["path"];
const _getEntityDataByImportType = fetcher.path(getEntityDataByImportTypePath).method("get").create();

export const getClientImportSummaries = async (args: GetClientImportSummariesArgs): Promise<ImportSummary[]> => process(_getClientImportSummaries(args));
const getClientImportSummariesPath = "/api/client/{clientId}/importType/{importType}/importsummaries";
export type GetClientImportSummariesArgs = paths[typeof getClientImportSummariesPath]["get"]["parameters"]["path"];
const _getClientImportSummaries = fetcher.path(getClientImportSummariesPath).method("get").create();

export const downloadClientImportTemplate = async (args: DownloadClientImportTemplateArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadClientImportTemplatePath, args), args.progressCallback);
const downloadClientImportTemplatePath = "/api/client/{clientId}/importType/{importType}/emptytemplate";
export type DownloadClientImportTemplateArgs = paths[typeof downloadClientImportTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const downloadClientImportTemplateWithData = async (args: DownloadClientImportTemplateWithDataArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadClientImportTemplateWithDataPath, args), args.progressCallback);
const downloadClientImportTemplateWithDataPath = "/api/client/{clientId}/importType/{importType}/data";
export type DownloadClientImportTemplateWithDataArgs = paths[typeof downloadClientImportTemplateWithDataPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const uploadClientDataFile = async (args: UploadClientDataFileArgs): Promise<void> =>
    uploadFile(applyParams(uploadClientDataFilePath, args), args.fileContainer);
const uploadClientDataFilePath = "/api/client/{clientId}/importType/{importType}/import";
export type UploadClientDataFileArgs = paths[typeof uploadClientDataFilePath]["post"]["parameters"]["path"] & { fileContainer: FileContainer };

export const downloadEntityImportTemplate = async (args: DownloadEntityImportTemplateArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadEntityImportTemplatePath, args), args.progressCallback);
const downloadEntityImportTemplatePath = "/api/client/{clientId}/entity/{entityId}/importType/{importType}/emptytemplate";
export type DownloadEntityImportTemplateArgs = paths[typeof downloadEntityImportTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const downloadEntityImportTemplateWithData = async (args: DownloadEntityImportTemplateWithDataArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadEntityImportTemplateWithDataPath, args), args.progressCallback);
const downloadEntityImportTemplateWithDataPath = "/api/client/{clientId}/entity/{entityId}/importType/{importType}/data";
export type DownloadEntityImportTemplateWithDataArgs = paths[typeof downloadEntityImportTemplateWithDataPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const uploadEntityDataFile = async (args: UploadEntityDataFileArgs): Promise<void> =>
    uploadFile(applyParams(uploadEntityDataFilePath, args), args.fileContainer);
const uploadEntityDataFilePath = "/api/client/{clientId}/entity/{entityId}/importType/{importType}/import";
export type UploadEntityDataFileArgs = paths[typeof uploadEntityDataFilePath]["post"]["parameters"]["path"] & { fileContainer: FileContainer };

export const downloadImportFile = async (args: DownloadFileArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadImportFilePath, args), args.progressCallback);
const downloadImportFilePath = "/api/client/{clientId}/importsummary/{importSummaryId}/file";
export type DownloadFileArgs = paths[typeof downloadImportFilePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const downloadImportFileForReview = async (args: DownloadFileArgsForReview): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadImportFilePathForReview, args), args.progressCallback);
const downloadImportFilePathForReview = "/api/client/{clientId}/importsummary/{importSummaryId}/review";
export type DownloadFileArgsForReview = paths[typeof downloadImportFilePathForReview]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const performImportAction = async (args: PerformImportActionArgs): Promise<ImportSummary> => process(_performImportAction(args));
const performImportActionPath = "/api/client/{clientId}/importSummary/{importSummaryId}/action/{importAction}";
export type PerformImportActionArgs = paths[typeof performImportActionPath]["post"]["parameters"]["path"];
const _performImportAction = fetcher.path(performImportActionPath).method("post").create();

export const performCalculationAction = async (args: PerformCalculationActionArgs): Promise<EntityCalculationResponse> =>
    process(_performCalculationAction(args));
const performCalculationActionPath = "/api/client/{clientId}/entity/{entityId}/calculation/{calculationHistoryId}/action/{calculationAction}";
export type PerformCalculationActionArgs = paths[typeof performCalculationActionPath]["post"]["parameters"]["path"];
const _performCalculationAction = fetcher.path(performCalculationActionPath).method("post").create();

export type CalculationHistoryResponse = components["schemas"]["CalculationHistoryResponse"];
export const getEntityCalculationSummary = async (args: GetEntityCalculationSummaryArgs): Promise<EntityCalculationSummary> =>
    process(_getEntityCalculationSummary(args));
export type EntityCalculationSummary = components["schemas"]["EntityCalculationSummaryResponse"];
const getEntityCalculationSummaryPath = "/api/client/{clientId}/entity/{entityId}/calculation";
export type GetEntityCalculationSummaryArgs = paths[typeof getEntityCalculationSummaryPath]["get"]["parameters"]["path"];
const _getEntityCalculationSummary = fetcher.path(getEntityCalculationSummaryPath).method("get").create();

export const getCalculationRunsForImmediateChildren = async (args: GetCalculationRunsForImmediateChildrenArgs): Promise<EntityCalculationResponse[]> =>
    process(_getCalculationRunsForImmediateChildren(args));
export type EntityCalculationResponse = components["schemas"]["EntityCalculationResponse"];
const getCalculationRunsForImmediateChildrenPath = "/api/client/{clientId}/entity/{entityId}/subentity/acceptedcalculation";
export type GetCalculationRunsForImmediateChildrenArgs = paths[typeof getCalculationRunsForImmediateChildrenPath]["get"]["parameters"]["path"];
const _getCalculationRunsForImmediateChildren = fetcher.path(getCalculationRunsForImmediateChildrenPath).method("get").create();

export const getRoundingPartnerDetails = async (args: GetRoundingPartnerDetailsArgs): Promise<RoundingPartnerDetails> =>
    process(_getRoundingPartnerDetails(args));
export type RoundingPartnerDetails = components["schemas"]["RoundingPartnerDetails"];
const getRoundingPartnerDetailsPath = "/api/client/{clientId}/entity/{entityId}/roundingPartnerDetails";
export type GetRoundingPartnerDetailsArgs = paths[typeof getRoundingPartnerDetailsPath]["get"]["parameters"]["path"];
const _getRoundingPartnerDetails = fetcher.path(getRoundingPartnerDetailsPath).method("get").create();

export const triggerCalculation = async (args: TriggerCalculationArgs): Promise<EntityCalculationResponse> => process(_triggerCalculation(args));
export type CalculationModel = components["schemas"]["CalculationModel"];
const triggerCalculationPath = "/api/client/{clientId}/entity/{entityId}/calculation";
export type TriggerCalculationArgs = paths[typeof triggerCalculationPath]["post"]["parameters"]["path"] & CalculationModel;
const _triggerCalculation = fetcher.path(triggerCalculationPath).method("post").create();

export const getClientReportHistory = async (args: GetClientReportHistoryArgs): Promise<ClientReport[]> => process(_getClientReportHistory(args));
export type ClientReport = components["schemas"]["ClientReportResponse"];
const getClientReportHistoryPath = "/api/client/{clientId}/report/history";
export type GetClientReportHistoryArgs = paths[typeof getClientReportHistoryPath]["get"]["parameters"]["path"];
const _getClientReportHistory = fetcher.path(getClientReportHistoryPath).method("get").create();

export const getClientReportCategory = async (args: GetClientReportCategoryArgs): Promise<ClientReportCategory> => process(_getClientReportCategory(args));
export type ClientReportCategory = components["schemas"]["ClientReportCategoryResponse"];
const getClientReportCategoryPath = "/api/client/{clientId}/report/category";
export type GetClientReportCategoryArgs = paths[typeof getClientReportCategoryPath]["get"]["parameters"]["path"];
const _getClientReportCategory = fetcher.path(getClientReportCategoryPath).method("get").create();

export const triggerClientReport = async (args: TriggerClientReportArgs): Promise<ClientReport> => process(_triggerClientReport(args));
export type ClientReportModel = components["schemas"]["ClientReportModel"];
const triggerClientReportPath = "/api/client/{clientId}/report";
export type TriggerClientReportArgs = paths[typeof triggerClientReportPath]["post"]["parameters"]["path"] & ClientReportModel;
const _triggerClientReport = fetcher.path(triggerClientReportPath).method("post").create();

export const getEntityReportHistory = async (args: GetEntityReportHistoryArgs): Promise<EntityReport[]> => process(_getEntityReportHistory(args));
export type EntityReport = components["schemas"]["EntityReportResponse"];
const getEntityReportHistoryPath = "/api/client/{clientId}/entity/{entityId}/report/history";
export type GetEntityReportHistoryArgs = paths[typeof getEntityReportHistoryPath]["get"]["parameters"]["path"];
const _getEntityReportHistory = fetcher.path(getEntityReportHistoryPath).method("get").create();

export const getEntityReportCategory = async (args: GetEntityReportCategoryArgs): Promise<EntityReportCategory> => process(_getEntityReportCategory(args));
export type EntityReportCategory = components["schemas"]["EntityReportCategoryResponse"];
const getEntityReportCategoryPath = "/api/client/{clientId}/entity/{entityId}/report/category";
export type GetEntityReportCategoryArgs = paths[typeof getEntityReportCategoryPath]["get"]["parameters"]["path"];
const _getEntityReportCategory = fetcher.path(getEntityReportCategoryPath).method("get").create();

export const triggerEntityReport = async (args: TriggerEntityReportArgs): Promise<EntityReport> => process(_triggerEntityReport(args));
export type EntityReportModel = components["schemas"]["EntityReportModel"];
const triggerEntityReportPath = "/api/client/{clientId}/entity/{entityId}/report";
export type TriggerEntityReportArgs = paths[typeof triggerEntityReportPath]["post"]["parameters"]["path"] & EntityReportModel;
const _triggerEntityReport = fetcher.path(triggerEntityReportPath).method("post").create();

export const downloadReport = async (args: DownloadReportArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadReportPath, args), args.progressCallback);
const downloadReportPath = "/api/client/{clientId}/report/{reportId}";
export type DownloadReportArgs = paths[typeof downloadReportPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const deleteReport = async (args: DeleteReportArgs): Promise<unknown> => process(_deleteReport(args));
const deleteReportPath = "/api/client/{clientId}/report/{reportId}";
export type DeleteReportArgs = paths[typeof deleteReportPath]["delete"]["parameters"]["path"];
const _deleteReport = fetcher.path(deleteReportPath).method("delete").create();

export const getK1PackageHistory = async (args: GetK1PackageHistoryArgs): Promise<K1Package[]> => process(_getK1PackageHistory(args));
export type K1Package = components["schemas"]["PackageResponse"];
const getK1PackageHistoryPath = "/api/client/{clientId}/entity/{entityId}/package/history";
export type GetK1PackageHistoryArgs = paths[typeof getK1PackageHistoryPath]["get"]["parameters"]["path"];
const _getK1PackageHistory = fetcher.path(getK1PackageHistoryPath).method("get").create();

export const getClientPartners = async (args: GetClientPartnerArgs): Promise<Partner[]> => process(_getClientPartners(args));
export type Partner = components["schemas"]["PartnerResponse"];
const getClientPartnerPath = "/api/client/{clientId}/partner";
export type GetClientPartnerArgs = paths[typeof getClientPartnerPath]["get"]["parameters"]["path"];
const _getClientPartners = fetcher.path(getClientPartnerPath).method("get").create();

export const getEntityPartners = async (args: GetEntityPartnerArgs): Promise<Partner[]> => process(_getEntityPartners(args));
const getEntityPartnerPath = "/api/client/{clientId}/entity/{entityId}/partner";
export type GetEntityPartnerArgs = paths[typeof getEntityPartnerPath]["get"]["parameters"]["path"];
const _getEntityPartners = fetcher.path(getEntityPartnerPath).method("get").create();

export const triggerK1Package = async (args: TriggerK1PackageArgs): Promise<TriggerK1PackageResponse> => process(_triggerK1Package(args));
export type TriggerK1PackageResponse = components["schemas"]["PackageResponse"];
export type K1PackageModel = components["schemas"]["PackageModel"];
const triggerK1PackagePath = "/api/client/{clientId}/entity/{entityId}/package";
export type TriggerK1PackageArgs = paths[typeof triggerK1PackagePath]["post"]["parameters"]["path"] & K1PackageModel;
const _triggerK1Package = fetcher.path(triggerK1PackagePath).method("post").create();

export const downloadK1Package = async (args: DownloadK1PackageArgs): Promise<DownloadFunctionResult> =>
    downloadFileAsBlob(applyParams(downloadK1PackagePath, args), args.progressCallback);
const downloadK1PackagePath = "/api/client/{clientId}/package/{packageId}";
export type DownloadK1PackageArgs = paths[typeof downloadK1PackagePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};

export const deleteK1Package = async (args: DeleteK1PackageArgs): Promise<unknown> => process(_deleteK1Package(args));
const deleteK1PackagePath = "/api/client/{clientId}/package/{packageId}";
export type DeleteK1PackageArgs = paths[typeof deleteK1PackagePath]["delete"]["parameters"]["path"];
const _deleteK1Package = fetcher.path(deleteK1PackagePath).method("delete").create();

//User Management
export const getCurrentUser = async (): Promise<User> => process(_getCurrentUser({}));
export type User = components["schemas"]["UserResponse"];
export const _getCurrentUser = fetcher.path("/api/user").method("get").create();

export const getRoles = async (): Promise<Role[]> => process(_getRoles({}));
const getRolesPath = "/api/roles";
export type Role = components["schemas"]["RoleResponse"];
const _getRoles = fetcher.path(getRolesPath).method("get").create();

export const searchUsers = async (args: SearchUsersArgs): Promise<User[]> => process(_searchUsers(args));
const searchUsersPath = "/api/users/search";
export type SearchUsersArgs = NonNullable<paths[typeof searchUsersPath]["get"]["parameters"]["query"]>;
export const _searchUsers = fetcher.path(searchUsersPath).method("get").create();

export const getClientUsers = async (args: GetClientUsersArgs): Promise<ClientUser[]> => process(_getClientUsers(args));
export type ClientUser = components["schemas"]["ClientUserResponse"];
const getClientUsersPath = "/api/client/{clientId}/users";
export type GetClientUsersArgs = paths[typeof getClientUsersPath]["get"]["parameters"]["path"];
export const _getClientUsers = fetcher.path(getClientUsersPath).method("get").create();

export const deleteUserFromClient = async (args: DeleteUserFromClientArgs): Promise<unknown> => process(_deleteUserFromClient(args));
const deleteUserFromClientPath = "/api/client/{clientId}/user/{userId}";
export type DeleteUserFromClientArgs = paths[typeof deleteUserFromClientPath]["delete"]["parameters"]["path"];
export const _deleteUserFromClient = fetcher.path(deleteUserFromClientPath).method("delete").create();

export const addOrUpdateUsersOnClient = async (args: AddUpdateUserOnClientArgs): Promise<User[]> => {
    // @ts-ignore
    const params: AddUpdateUserOnClientParams = [...args.userRoles];
    params.clientId = args.clientId;
    return process(_addUpdateUsersOnClient(params));
};
const addUpdateUsersOnClientPath = "/api/client/{clientId}/users";
export type UserRole = components["schemas"]["ClientUserRole"];
export type AddUpdateUserOnClientArgs = { clientId: string; userRoles: Array<UserRole> };
export type AddUpdateUserOnClientParams = { clientId: string } & Array<UserRole>;
export const _addUpdateUsersOnClient = fetcher.path(addUpdateUsersOnClientPath).method("post").create();
