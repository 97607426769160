import { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";
import { ImportStatus, ImportStatusDetailsLookup } from "../../enums";

export const ImportStatusIcon: FC<{ status?: ImportStatus } & IconProps> = ({ status, ...rest }) => {
    if (status) {
        return <Icon {...ImportStatusDetailsLookup[status]} {...rest} aria-label={ImportStatusDetailsLookup[status].title} />;
    } else {
        return null;
    }
};
