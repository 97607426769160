import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    VStack,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ModalForResultContent } from "am-tax-fe-core";

type InputArgs = void;
type Form = {
    packageName: string;
};

export const PackageNameForm: ModalForResultContent<InputArgs, string> = ({ onResult, close }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Form>();

    const onSubmit: SubmitHandler<Form> = async data => {
        onResult(data.packageName);
    };

    return (
        <ModalContent>
            <ModalHeader>Package Name</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction={"column"} gap={"2rem"}>
                        <fieldset>
                            <VStack spacing={"1rem"}>
                                <FormControl isInvalid={!!errors?.packageName}>
                                    <FormLabel>What would you like to name your package?</FormLabel>
                                    <Input {...register("packageName", { required: "Package Name is required." })} />
                                    <FormErrorMessage>{errors?.packageName?.message}</FormErrorMessage>
                                </FormControl>
                            </VStack>
                        </fieldset>
                        <HStack justify={"space-between"}>
                            <Button
                                variant={"ghost"}
                                onClick={() => {
                                    close();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant={"primary"}>
                                Continue
                            </Button>
                        </HStack>
                    </Flex>
                </form>
            </ModalBody>
        </ModalContent>
    );
};
