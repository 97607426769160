import { FunctionComponent, useMemo, useState } from "react";

import { Box, Button, Card, Divider, VStack } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { Page } from "../Page";
import { Filter, Shimmer } from "am-tax-fe-core";
import { useClients } from "../../api";

export const MyClientsPage: FunctionComponent = () => {
    const navigate = useNavigate();

    const clientsQuery = useClients();
    const [filter, setFilter] = useState("");
    const clients = clientsQuery.data || [];
    const filteredClients = useMemo(() => {
        const clients = clientsQuery.data || [];
        return filter.length ? clients.filter(client => client.name.toLowerCase().includes(filter.toLowerCase())) : clients;
    }, [clientsQuery.data, filter]);

    function updateFilter(value: string) {
        setFilter(value);
    }

    return (
        <Page title="My Clients">
            <Card overflow={"hidden"} minHeight={"50%"} bgGradient={`linear(to-br, blue.700, blue.500)`}>
                <VStack padding="2rem" spacing="1rem" alignItems="stretch">
                    <Box>
                        <Filter placeholder="Filter Clients" onChange={updateFilter} count={{ total: clients.length, shown: filteredClients.length }} />
                    </Box>

                    {!clientsQuery.isLoading && clientsQuery.data?.length === 0 && (
                        <Box textAlign="center" color={"whiteAlpha.800"}>
                            No Clients Found
                        </Box>
                    )}

                    <VStack
                        spacing="1px"
                        alignItems="stretch"
                        overflowY="auto"
                        divider={
                            <Box border={0}>
                                <Divider borderColor="blue.300" />
                            </Box>
                        }
                    >
                        {clientsQuery.isLoading
                            ? Array(7)
                                  .fill(null)
                                  .map((val, i) => <Shimmer key={i} height="40px" mode={"dark"} />)
                            : null}
                        {filteredClients?.map(client => (
                            <Button
                                key={client.id}
                                justifyContent="space-between"
                                onClick={() => navigate(`/client/${client.id}/clientSummary`)}
                                borderRadius={0}
                                paddingRight="2rem"
                                color={"whiteAlpha.900"}
                                _hover={{ bg: "whiteAlpha.400" }}
                                backgroundColor={"transparent"}
                                fontWeight={"normal"}
                            >
                                {client.name}
                            </Button>
                        ))}
                    </VStack>
                </VStack>
            </Card>
        </Page>
    );
};
