import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { ImportType } from "../enums";
import { SearchUsersArgs } from "./api";

export const queryKeys = createQueryKeyStore({
    clients: {
        all: null,
        byId: (clientId: string) => [clientId],
        clientKpis: (clientId: string) => [clientId],
        clientReportHistory: (clientId: string) => [clientId],
        clientReportCategory: (clientId: string) => [clientId],
        partners: (clientId: string) => [clientId],
    },
    clientConfiguration: {
        byId: (clientId: string) => [clientId],
    },
    entities: {
        byClientId: (clientId: string) => [clientId],
        byClientAndEntityId: (clientId: string, entityId: string) => [clientId, entityId],
        entitySummary: (clientId: string, entityId: string) => [clientId, entityId],
        entityKpis: (clientId: string, entityId: string) => [clientId, entityId],
        hierarchy: (clientId: string) => [clientId],
        income: (clientId: string, entityId: string) => [clientId, entityId],
        entityImportSummaries: (clientId: string, entityId: string, importType: ImportType) => [clientId, entityId, importType],
        importSummaryById: (clientId: string, importTypeSummaryId: string) => [clientId, importTypeSummaryId],
        clientImportSummaries: (clientId: string, importType: ImportType) => [clientId, importType],
        entityCalculationSummary: (clientId: string, entityId: string) => [clientId, entityId],
        entityCalculationRunsForImmediateChildren: (clientId: string, entityId: string) => [clientId, entityId],
        entityReportHistory: (clientId: string, entityId: string) => [clientId, entityId],
        entityReportCategory: (clientId: string, entityId: string) => [clientId, entityId],
        entityK1PackageHistory: (clientId: string, entityId: string) => [clientId, entityId],
        partners: (clientId: string, entityId: string) => [clientId, entityId],
        roundingPartnerDetails: (clientId: string, entityId: string) => [clientId, entityId],
    },
    worksheetData: {
        byImportSummaryId: (clientId: string, importTypeSummaryId: string) => [clientId, importTypeSummaryId],
        byImportTypeForClient: (clientId: string, importType: ImportType) => [clientId, importType],
        byImportTypeForEntity: (clientId: string, entityId: string, importType: ImportType) => [clientId, entityId, importType],
    },
    users: {
        current: null,
        byClientId: (clientId: string) => [clientId],
        search: (args: SearchUsersArgs) => [args],
    },
    roles: {
        all: null,
    },
});
