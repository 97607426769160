export enum TaxJurisdiction {
    Federal = 1,
    State = 2,
    //International = 3, (Add this later once we support internal import)
}

type TaxJurisdictionDetails = { title: string };

export const taxJurisdictions = Object.values(TaxJurisdiction).filter(value => typeof value === "number") as TaxJurisdiction[];

const TaxJurisdictionDetailsLookup: Record<TaxJurisdiction, TaxJurisdictionDetails> = {
    [TaxJurisdiction.Federal]: { title: "Federal" },
    [TaxJurisdiction.State]: { title: "State" },
    //[TaxJurisdiction.International]: { title: "International" },
};

export const displayTaxJurisdiction = (jurisdiction: TaxJurisdiction) => {
    return TaxJurisdictionDetailsLookup[jurisdiction].title;
};
