import { FunctionComponent, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ClientReportModel, TriggerClientReportArgs, useClientPartners, useClientReportCategory, useEntities, useTriggerClientReport } from "../../api";
import {
    Box,
    Button,
    Card,
    CardBody,
    Checkbox,
    CheckboxGroup,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Heading,
    List,
    ListItem,
    Radio,
    RadioGroup,
    VStack,
    chakra,
} from "@chakra-ui/react";
import { ReportHistoryGrid } from "./ReportHistoryGrid";
import { MultiSelectControlled, Shimmer, useModalForResult, useToast } from "am-tax-fe-core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PackageNameForm } from "../../components/PackageNameForm";
import { ClientPageTemplate } from "../ClientSummaryPage/ClientPageTemplate";
import { getEntityName } from "../../utils";
import { ClientReportCategory } from "../../enums/ClientReportCategory";

export const ClientReportsPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    const toast = useToast();
    const triggerReport = useTriggerClientReport();

    const clientReportCategoryQuery = useClientReportCategory({ clientId });
    const clientReportCategory = clientReportCategoryQuery.data || null;
    const { ModalForResult, openModalForResult } = useModalForResult(PackageNameForm);

    const {
        getValues,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ClientReportModel>();

    const reportId = watch("reportId");

    const entitiesQuery = useEntities(reportId === ClientReportCategory.DealByDeal ? clientId : undefined);
    const partnersQuery = useClientPartners({ clientId: reportId === ClientReportCategory.CrossEntityPartner ? clientId : undefined });

    const allEntityIds = useMemo(() => {
        return entitiesQuery.data?.map(entity => entity.id) || [];
    }, [entitiesQuery.data]);

    const allChecked = () => {
        return allEntityIds.length === getValues().entities?.length;
    };

    const someChecked = () => {
        const entityCounts = getValues().entities?.length || 0;
        return entityCounts > 0 && entityCounts < allEntityIds.length;
    };

    const noneChecked = () => {
        return !getValues().entities || getValues().entities?.length === 0;
    };

    const onSubmit: SubmitHandler<ClientReportModel> = async data => {
        const packageName = await openModalForResult();
        const args = {
            clientId,
            ...data,
            packageName,
        } as TriggerClientReportArgs;
        await triggerReport.mutateAsync(args);
        toast({
            title: "Report Initiated",
            description: "The report was added to the queue, and is being processed.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    if (!clientId) {
        return "This page requires clientId to render.";
    }

    return (
        <ClientPageTemplate
            title={"Client Reports"}
            clientId={clientId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/dataImports`);
            }}
        >
            <ModalForResult />
            <VStack align={"start"} spacing={"2rem"}>
                <Card bg={"blue.25"}>
                    <CardBody p={"2rem 3rem"}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid gridTemplateColumns={"auto 1fr auto"} columnGap={"3rem"} w={"fit-content"}>
                                <Heading>Report</Heading>
                                <Heading>
                                    {reportId === ClientReportCategory.DealByDeal && "Entities"}
                                    {reportId === ClientReportCategory.CrossEntityPartner && "Partners"}
                                </Heading>
                                <GridItem alignSelf={"center"} rowSpan={2}>
                                    <Button
                                        variant={"primary"}
                                        type={"submit"}
                                        isLoading={triggerReport.isPending}
                                        isDisabled={clientReportCategoryQuery.isLoading}
                                    >
                                        Generate Report
                                    </Button>
                                </GridItem>
                                <Box>
                                    <FormControl isInvalid={!!errors.reportId}>
                                        <Controller
                                            name="reportId"
                                            control={control}
                                            rules={{ required: "You must select a report." }}
                                            render={({ field }) => (
                                                <Shimmer isLoading={clientReportCategoryQuery.isLoading} minHeight={"5rem"} minWidth={"12em"}>
                                                    <RadioGroup value={String(field.value)} onChange={value => field.onChange(Number(value))}>
                                                        <List>
                                                            {clientReportCategory?.clientReportCategories?.map((item, index) => {
                                                                return (
                                                                    <ListItem key={index} mb={".5rem"}>
                                                                        <Radio value={String(item.id)}>
                                                                            <chakra.span whiteSpace={"nowrap"}>{item.name}</chakra.span>
                                                                        </Radio>
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </RadioGroup>
                                                </Shimmer>
                                            )}
                                        />
                                        <FormErrorMessage>{errors.reportId && errors.reportId.message}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                                <Box minWidth={"10rem"}>
                                    {reportId === ClientReportCategory.DealByDeal && (
                                        <FormControl isInvalid={!!errors.entities}>
                                            <Controller
                                                name="entities"
                                                control={control}
                                                rules={{ required: "You must select at least 1 entity." }}
                                                render={({ field }) => (
                                                    <Shimmer isLoading={entitiesQuery.isLoading}>
                                                        <CheckboxGroup value={field.value || []} onChange={values => field.onChange(values)}>
                                                            <Box
                                                                pb={".5rem"}
                                                                px={"1rem"}
                                                                mb={".5rem"}
                                                                borderBottom={"1px solid var(--chakra-colors-chakra-border-color)"}
                                                            >
                                                                <Checkbox
                                                                    isChecked={allChecked()}
                                                                    isIndeterminate={someChecked()}
                                                                    onChange={() => {
                                                                        if (someChecked() || noneChecked()) {
                                                                            field.onChange(allEntityIds);
                                                                        } else {
                                                                            field.onChange([]);
                                                                        }
                                                                    }}
                                                                >
                                                                    Select All
                                                                </Checkbox>
                                                            </Box>
                                                            <Box maxHeight={"15rem"} overflowY={"auto"}>
                                                                <List px={"1rem"}>
                                                                    {entitiesQuery.data?.map((entity, index) => {
                                                                        return (
                                                                            <ListItem key={index} mb={".5rem"}>
                                                                                <Checkbox value={String(entity.id)} whiteSpace={"nowrap"}>
                                                                                    {getEntityName(entity)}
                                                                                </Checkbox>
                                                                            </ListItem>
                                                                        );
                                                                    })}
                                                                </List>
                                                            </Box>
                                                        </CheckboxGroup>
                                                    </Shimmer>
                                                )}
                                            />
                                            <FormErrorMessage>{errors.entities && errors.entities.message}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                    {reportId === ClientReportCategory.CrossEntityPartner && (
                                        <FormControl isInvalid={!!errors.partners}>
                                            <Shimmer isLoading={partnersQuery.isLoading}>
                                                <Box minWidth={"13rem"}>
                                                    <MultiSelectControlled
                                                        control={control}
                                                        name={"partners"}
                                                        placeholder={"Select Partners"}
                                                        rules={{ required: "You must select at least one partner" }}
                                                        options={partnersQuery.data?.map(p => ({
                                                            value: p.id,
                                                            label: `${p.partnerNumber} | ${p.firstName} ${p.lastName}`,
                                                        }))}
                                                    />
                                                </Box>
                                            </Shimmer>
                                            <FormErrorMessage>{errors.partners && errors.partners.message}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Box>
                            </Grid>
                        </form>
                    </CardBody>
                </Card>
                <Box w={"full"}>
                    <Heading fontSize={"md"}>Generated Reports</Heading>
                    <ReportHistoryGrid clientId={clientId} />
                </Box>
            </VStack>
        </ClientPageTemplate>
    );
};
