import { IconCircle, IconCircleFilled, IconProgress } from "@tabler/icons-react";
import { FC } from "react";

export enum CalculationStatus {
    Processing = 1,
    Errors = 2,
    CompleteWithErrors = 3,
    Complete = 4,
    Accepted = 5,
}

type CalculationStatusDetails = { as: FC; color?: string; title: string };

export const CalculationStatusLookup: Record<CalculationStatus, CalculationStatusDetails> = {
    [CalculationStatus.Processing]: { as: IconProgress, title: "Processing", color: "blue.500" },
    [CalculationStatus.Errors]: { as: IconCircle, title: "Errors", color: "red.500" },
    [CalculationStatus.CompleteWithErrors]: { as: IconCircle, title: "Complete With Errors", color: "orange.500" },
    [CalculationStatus.Complete]: { as: IconCircle, title: "Complete", color: "green.300" },
    [CalculationStatus.Accepted]: { as: IconCircleFilled, title: "Complete", color: "green.300" },
};

export const displayCalculationStatus = (calculationStatus: CalculationStatus) => {
    return CalculationStatusLookup[calculationStatus]?.title;
};
