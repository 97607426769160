import { FC } from "react";
import { SimpleGrid } from "@chakra-ui/react";

import { EntityIncomeCard } from "./EntityIncomeCard";
import { showEntitySummaryAtom } from "../../atoms";
import { EntityHierarchyWidget, NodeClickedEvent } from "../../components/EntityHierarchyWidget";
import { KpiCard } from "../../components/KpiCard";
import { Page, PageProps } from "../Page";
import { useAtom } from "jotai/index";
import { useEntityKPIs } from "../../api";

export type EntityPageTemplateProps = Omit<PageProps, "collapsableArea"> & { clientId: string; entityId: string; nodeClicked: NodeClickedEvent };

export const EntityPageTemplate: FC<EntityPageTemplateProps> = ({ clientId, entityId, nodeClicked, ...pageProps }) => {
    const [show, setShow] = useAtom(showEntitySummaryAtom);
    const entityKPIsQuery = useEntityKPIs({ clientId, entityId });

    return (
        <Page
            {...pageProps}
            collapsableArea={{
                show,
                onToggle: () => setShow(!show),
                areaContent: (
                    <SimpleGrid columns={3} spacing={"1rem"} p={"1.5rem 3rem"} bg={"gray.50"}>
                        <EntityIncomeCard clientId={clientId} entityId={entityId} />
                        <KpiCard kpis={entityKPIsQuery.data} />
                        <EntityHierarchyWidget clientId={clientId} entityId={entityId} nodeClicked={nodeClicked} />
                    </SimpleGrid>
                ),
            }}
        />
    );
};
