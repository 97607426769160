export enum ImportType {
    // Client Level
    Entity = 1,
    FederalK1Lines = 2,
    Partner = 3,
    StateActivation = 13,

    // Entity Level
    PartnerInfo = 4,
    FederalK1Input = 5,
    SpecialAllocations = 6,
    SidePockets = 7,
    StateCompositeAndWithholdingElection = 8,
    StateApportionment = 10,
    StateK1Input = 11,
    StateSpecialAllocations = 12,
}

type ImportTypeDetails = { title: string };

const ImportTypeDetailsLookup: Record<ImportType, ImportTypeDetails> = {
    // Client Level
    [ImportType.Entity]: { title: "Entities" },
    [ImportType.FederalK1Lines]: { title: "Federal K-1 Lines" },
    [ImportType.Partner]: { title: "Partners" },
    [ImportType.StateActivation]: { title: "State Activation" },

    // Entity Level
    [ImportType.PartnerInfo]: { title: "Partner Info" },
    [ImportType.FederalK1Input]: { title: "Federal K-1 Input" },
    [ImportType.SpecialAllocations]: { title: "Special Allocations" },
    [ImportType.SidePockets]: { title: "Side Pockets" },
    [ImportType.StateCompositeAndWithholdingElection]: { title: "State Composite/Withholding Election" },
    [ImportType.StateApportionment]: { title: "State Apportionment" },
    [ImportType.StateK1Input]: { title: "State K-1 Input" },
    [ImportType.StateSpecialAllocations]: { title: "State Special Allocations" },
};

export const displayImportType = (importType: ImportType) => {
    return ImportTypeDetailsLookup[importType].title;
};
