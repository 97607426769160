import { FunctionComponent, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { K1PackageModel, TriggerK1PackageArgs, useEntityPartners, useTriggerK1Package } from "../../api";
import { Box, Button, Card, CardBody, Checkbox, CheckboxGroup, Flex, FormControl, FormErrorMessage, Grid, GridItem, Heading, VStack } from "@chakra-ui/react";
import { K1PackagesHistoryGrid } from "./K1PackagesHistoryGrid";
import { AgGrid, useModalForResult, useToast } from "am-tax-fe-core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PackageNameForm } from "../../components/PackageNameForm";
import { ICellRendererParams } from "ag-grid-community";
import { EntityPageTemplate } from "../EntityDataImportsPage/EntityPageTemplate";
import { PackageType } from "../../enums";

export const K1PackagesPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientId, entityId } = useParams();
    const toast = useToast();
    const triggerK1Package = useTriggerK1Package();

    const partnersQuery = useEntityPartners({ clientId, entityId });
    const partners = partnersQuery.data || null;
    const { ModalForResult, openModalForResult } = useModalForResult(PackageNameForm);

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm<K1PackageModel>();

    const onSubmit: SubmitHandler<K1PackageModel> = async data => {
        data.packageType = PackageType.ScheduleK1;
        const packageName = await openModalForResult();
        const args = {
            clientId,
            entityId,
            ...data,
            name: packageName,
        } as TriggerK1PackageArgs;
        await triggerK1Package.mutateAsync(args);
        toast({
            title: "K1 Package",
            description: "The K1 package is being created.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const partnerIds = useMemo(() => (partners || []).map(partner => partner.id), [partners]);

    const allChecked = () => {
        return partnerIds.length === getValues().partners?.length;
    };

    const someChecked = () => {
        return getValues().partners?.length > 0 && getValues().partners?.length < partnerIds.length;
    };

    const noneChecked = () => {
        return getValues().partners?.length === 0;
    };

    if (!clientId || !entityId) {
        return "This page requires clientId and entityId to render.";
    }

    return (
        <EntityPageTemplate
            title={"K1 Packages"}
            clientId={clientId}
            entityId={entityId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/k1Packages`);
            }}
        >
            <ModalForResult />
            <VStack align={"start"} spacing={"2rem"}>
                <Card bg={"blue.25"}>
                    <CardBody p={"2rem 3rem"}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={!!errors.partners}>
                                <Controller
                                    name="partners"
                                    control={control}
                                    rules={{ required: "You must select at least one partner." }}
                                    render={({ field }) => (
                                        <Grid gridTemplateColumns={"2fr 1fr"} columnGap={"3rem"} w={"fit-content"}>
                                            <GridItem colSpan={2}>
                                                <Heading>Partner Selection</Heading>
                                            </GridItem>
                                            <GridItem>
                                                <Flex direction={"column"} gap={"1rem"}>
                                                    <Box ml={"1rem"}>
                                                        <Checkbox
                                                            isChecked={allChecked()}
                                                            isIndeterminate={someChecked()}
                                                            onChange={() => (someChecked() || noneChecked() ? field.onChange(partnerIds) : field.onChange([]))}
                                                        >
                                                            Select All
                                                        </Checkbox>
                                                    </Box>
                                                    <CheckboxGroup value={field.value || []} onChange={values => field.onChange(values)}>
                                                        <AgGrid
                                                            container={{ width: "calc(360px + 2rem)" }}
                                                            height={"15rem"}
                                                            rowData={partners}
                                                            onRowClicked={({ data: partner }) => {
                                                                const partnerIds = getValues().partners || [];
                                                                if (partnerIds.includes(partner!.id)) {
                                                                    field.onChange(partnerIds.filter(id => id !== partner!.id));
                                                                } else {
                                                                    field.onChange([...partnerIds, partner!.id]);
                                                                }
                                                            }}
                                                            columnDefs={[
                                                                {
                                                                    headerName: "Partners",
                                                                    field: "firstName",
                                                                    valueGetter: ({ data: partner }) => `${partner!.firstName} ${partner!.lastName}`,
                                                                    cellRenderer: ({ data: partner }: ICellRendererParams) => (
                                                                        <Checkbox value={partner.id} onClick={e => e.preventDefault()}>
                                                                            {partner!.firstName} {partner!.lastName}
                                                                        </Checkbox>
                                                                    ),
                                                                },
                                                            ]}
                                                        />
                                                    </CheckboxGroup>
                                                </Flex>
                                            </GridItem>
                                            <GridItem alignSelf={"center"} justifySelf={"center"} rowSpan={2}>
                                                <Button variant={"primary"} type={"submit"} isLoading={triggerK1Package.isPending}>
                                                    Generate K1s
                                                </Button>
                                            </GridItem>
                                            {errors.partners && (
                                                <GridItem justifySelf={"center"}>
                                                    <FormErrorMessage>{errors.partners.message}</FormErrorMessage>
                                                </GridItem>
                                            )}
                                        </Grid>
                                    )}
                                />
                            </FormControl>
                        </form>
                    </CardBody>
                </Card>
                <Box w={"full"}>
                    <Heading fontSize={"md"}>Generated K1 Packages</Heading>
                    <K1PackagesHistoryGrid clientId={clientId} entityId={entityId} />
                </Box>
            </VStack>
        </EntityPageTemplate>
    );
};
