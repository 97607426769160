export enum EntityType {
    Asset = 1,
    Branch = 2,
    ControlledForeignCorporation = 3,
    Corporation = 4,
    DisregardedEntity = 5,
    Estate = 6,
    HybridBranch = 7,
    Individual = 8,
    Partnership = 9,
    ReverseHybridCorporation = 10,
    SCorporation = 11,
    Trust = 12,
}
