import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EntityReportModel, TriggerEntityReportArgs, useEntityReportCategory, useTriggerEntityReport } from "../../api";
import { Box, Button, Card, CardBody, Checkbox, CheckboxGroup, FormControl, FormErrorMessage, Grid, GridItem, Heading, List, VStack } from "@chakra-ui/react";
import { ReportHistoryGrid } from "./ReportHistoryGrid";
import { useModalForResult, useToast } from "am-tax-fe-core";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PackageNameForm } from "../../components/PackageNameForm";
import { EntityPageTemplate } from "../EntityDataImportsPage/EntityPageTemplate";

export const EntityReportsPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientId, entityId } = useParams();
    const toast = useToast();
    const triggerReport = useTriggerEntityReport();

    const entityReportCategoryQuery = useEntityReportCategory({ clientId, entityId });
    const entityReportCategory = entityReportCategoryQuery.data || null;
    const { ModalForResult, openModalForResult } = useModalForResult(PackageNameForm);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<EntityReportModel>();

    const onSubmit: SubmitHandler<EntityReportModel> = async data => {
        const packageName = await openModalForResult();
        const args = {
            clientId,
            entityId,
            ...data,
            packageName,
        } as TriggerEntityReportArgs;
        await triggerReport.mutateAsync(args);
        toast({
            title: "Report Initiated",
            description: "The report was added to the queue, and is being processed.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    if (!clientId || !entityId) {
        return "This page requires clientId and entityId to render.";
    }

    return (
        <EntityPageTemplate
            title={"Entity Reports"}
            clientId={clientId}
            entityId={entityId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/entityreports`);
            }}
        >
            <ModalForResult />
            <VStack align={"start"} spacing={"2rem"}>
                <Card bg={"blue.25"}>
                    <CardBody p={"2rem 3rem"}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl isInvalid={!!errors.reportIds}>
                                <Controller
                                    name="reportIds"
                                    control={control}
                                    rules={{ required: "You must select at least one report." }}
                                    render={({ field }) => (
                                        <CheckboxGroup value={field.value || []} onChange={values => field.onChange(values)}>
                                            <Grid gridTemplateColumns={"1fr 1fr 1fr 1fr"} columnGap={"3rem"} w={"fit-content"}>
                                                <Heading>Pre-Allocation</Heading>
                                                <Heading>Federal-Allocations</Heading>
                                                <Heading>States</Heading>
                                                <GridItem alignSelf={"center"} rowSpan={2}>
                                                    <Button variant={"primary"} type={"submit"} isLoading={triggerReport.isPending}>
                                                        Generate Report
                                                    </Button>
                                                </GridItem>
                                                <List>
                                                    {entityReportCategory?.preAllocations?.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Checkbox value={String(item.id)}>{item.name}</Checkbox>
                                                            </li>
                                                        );
                                                    })}
                                                </List>
                                                <List>
                                                    {entityReportCategory?.federalAllocations?.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Checkbox value={String(item.id)}>{item.name}</Checkbox>
                                                            </li>
                                                        );
                                                    })}
                                                </List>
                                                <List>
                                                    {entityReportCategory?.states?.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Checkbox value={String(item.id)}>{item.name}</Checkbox>
                                                            </li>
                                                        );
                                                    })}
                                                </List>
                                                <GridItem colSpan={3} justifySelf={"center"}>
                                                    <FormErrorMessage>{errors.reportIds && errors.reportIds.message}</FormErrorMessage>
                                                </GridItem>
                                            </Grid>
                                        </CheckboxGroup>
                                    )}
                                />
                            </FormControl>
                        </form>
                    </CardBody>
                </Card>
                <Box w={"full"}>
                    <Heading fontSize={"md"}>Generated Reports</Heading>
                    <ReportHistoryGrid clientId={clientId} entityId={entityId} />
                </Box>
            </VStack>
        </EntityPageTemplate>
    );
};
