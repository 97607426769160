import { FC } from "react";
import { Box, HStack, Icon, IconButton } from "@chakra-ui/react";
import { ICellRendererParams } from "ag-grid-community";
import { AgGrid, DownloadButton, formatTimestampStr, useConfirm, useToast } from "am-tax-fe-core";
import { downloadReport, useClientDeleteReport, useClientReportHistory } from "../../api";
import { IconDownload, IconTrash } from "@tabler/icons-react";

type ReportHistoryGridProps = {
    clientId: string;
};

export const ReportHistoryGrid: FC<ReportHistoryGridProps> = ({ clientId }) => {
    const toast = useToast();
    const clientReportHistoryQuery = useClientReportHistory({ clientId });
    const deleteReport = useClientDeleteReport();
    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete Report", prompt: "Are you sure you want to delete this report?" });

    return (
        <>
            <ConfirmDialog />
            <AgGrid
                rowData={clientReportHistoryQuery.data}
                isLoading={clientReportHistoryQuery.isLoading}
                columnDefs={[
                    {
                        headerName: "Package Name",
                        field: "packageName",
                        sort: "asc",
                    },
                    {
                        headerName: "Generated By",
                        field: "user",
                        valueGetter: ({ data: report }) => `${report?.user?.firstName || ""} ${report?.user?.lastName || ""}`,
                    },
                    {
                        headerName: "Started",
                        field: "startDate",
                        cellRenderer: ({ data: report }: ICellRendererParams) => formatTimestampStr(report?.startDate.toString()),
                    },
                    {
                        headerName: "Finished",
                        field: "finishDate",
                        valueGetter: ({ data: report }) => formatTimestampStr(report?.finishDate?.toString()),
                    },
                    {
                        headerName: "Actions",
                        cellRenderer: ({ data: report }: ICellRendererParams) => (
                            <HStack>
                                <Box>
                                    <DownloadButton
                                        icon={<Icon as={IconDownload} />}
                                        aria-label={"download"}
                                        variant={"ghost"}
                                        bg={"transparent"}
                                        downloadFn={async progressCallback => {
                                            try {
                                                return await downloadReport({
                                                    clientId,
                                                    reportId: report.id,
                                                    progressCallback,
                                                });
                                            } catch (e) {
                                                toast({
                                                    title: "Error",
                                                    description: "The report package could not be downloaded",
                                                    status: "error",
                                                    duration: 5000,
                                                    isClosable: true,
                                                });
                                                throw e;
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <IconButton
                                        icon={<Icon as={IconTrash} />}
                                        aria-label={"delete"}
                                        variant={"ghost"}
                                        bg={"transparent"}
                                        onClick={async () => {
                                            await confirm();
                                            try {
                                                await deleteReport.mutateAsync({
                                                    clientId,
                                                    reportId: report?.id || "",
                                                });
                                                toast({
                                                    title: "Deleted",
                                                    description: "Report Deleted",
                                                    status: "success",
                                                    duration: 3000,
                                                    isClosable: true,
                                                });
                                            } catch (e) {
                                                toast({
                                                    title: "Error",
                                                    description: "The report package could not be deleted",
                                                    status: "error",
                                                    duration: 5000,
                                                    isClosable: true,
                                                });
                                            }
                                        }}
                                    />
                                </Box>
                            </HStack>
                        ),
                    },
                ]}
            />
        </>
    );
};
