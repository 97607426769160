import * as React from "react";
import { Outlet } from "react-router-dom";
import { Entity } from "../api";

export function withOutlet(element: React.ReactNode) {
    return {
        element: (
            <>
                {element}
                <Outlet />
            </>
        ),
    };
}

export function getEntityName(entity: Entity) {
    return `${entity.name1} ${entity.name2} ${entity.name3}`;
}

/**
 * This recursively finds and removes any properties from an object that have a value of null.
 * It will ignore any properties that contain arrays.
 * Returns a new object.
 * @param obj
 */
export function removeNulls<T extends Record<string, unknown>>(obj: T): T {
    const copy = {} as T;
    Object.keys(obj).forEach(key => {
        if (obj[key] !== null) {
            if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
                // @ts-expect-error - not worth the effort to get the types to work here
                copy[key] = removeNulls(obj[key] as Record<string, unknown>);
            } else {
                // @ts-expect-error - not worth the effort to get the types to work here
                copy[key] = obj[key];
            }
        }
    });
    return copy;
}
