import { useNavigate, useParams } from "react-router-dom";
import { ImportTypeResponse, useEntitySummary } from "../../api";
import { useAtom } from "jotai/index";
import { entityImportTabs } from "../../atoms";
import { paramToNum } from "am-tax-fe-core";
import { ImportType } from "../../enums";
import { TaxJurisdiction, taxJurisdictions } from "../../enums/TaxJurisdiction";
import { useCallback, useEffect, useMemo } from "react";

export const useImportTypeTabs = (rootPath: string) => {
    const navigate = useNavigate();
    const { clientId, entityId, taxJurisdiction: taxJurisdictionParam, importType: importTypeParam } = useParams();

    const importSummaryQuery = useEntitySummary({ clientId, entityId });
    const importSummary = importSummaryQuery.data || null;

    const [importTabs, setImportTabs] = useAtom(entityImportTabs);
    const { taxJurisdiction: currentTaxJurisdiction, importTypeByJurisdiction } = importTabs;
    const currentImportType = importTypeByJurisdiction[currentTaxJurisdiction];

    const importTypeFromParam = paramToNum(importTypeParam) as ImportType;
    const taxJurisdictionFromParam = paramToNum(taxJurisdictionParam) as TaxJurisdiction;
    const taxJurisdiction = taxJurisdictionFromParam || currentTaxJurisdiction || TaxJurisdiction.Federal;

    const allImportTypes = useMemo(() => importSummary?.importTypes || [], [importSummary?.importTypes]);
    const importTypesForJurisdiction = useMemo(
        () => allImportTypes.filter(importType => importType.taxJurisdiction === taxJurisdiction) || [],
        [allImportTypes, taxJurisdiction],
    );

    const importType: ImportType = importTypeFromParam || currentImportType || importTypesForJurisdiction[0]?.type || ImportType.PartnerInfo;

    useEffect(() => {
        if (currentImportType !== importTypeFromParam || currentTaxJurisdiction !== taxJurisdictionFromParam) {
            setImportTabs({
                importTypeByJurisdiction: { ...importTypeByJurisdiction, [taxJurisdictionFromParam]: importTypeFromParam },
                taxJurisdiction: taxJurisdictionFromParam,
            });
        }
    }, [currentImportType, importTypeFromParam, currentTaxJurisdiction, taxJurisdictionFromParam, setImportTabs, importTypeByJurisdiction]);

    const selectedTaxJurisdictionTab = useMemo(
        () => taxJurisdictions.findIndex((jurisdiction: TaxJurisdiction) => jurisdiction === taxJurisdiction),
        [taxJurisdiction],
    );

    const selectedImportTypeTab = useMemo(
        () => importTypesForJurisdiction.findIndex((importTypeResponse: ImportTypeResponse) => importTypeResponse.type === importType),
        [importTypesForJurisdiction, importType],
    );

    const taxJurisdictionTabChanged = useCallback(
        (index: number) => {
            const nextTaxJurisdiction = taxJurisdictions[index];
            const importTypesForNextJurisdiction = allImportTypes.filter(importType => importType.taxJurisdiction === nextTaxJurisdiction);
            const nextImportType = importTypeByJurisdiction[nextTaxJurisdiction] || importTypesForNextJurisdiction[0]?.type;
            navigate(`${rootPath}/${nextTaxJurisdiction}/${nextImportType}`);
        },
        [allImportTypes, importTypeByJurisdiction, navigate, rootPath],
    );

    const importTypeTabChanged = useCallback(
        (index: number) => {
            navigate(`${rootPath}/${taxJurisdiction}/${importTypesForJurisdiction[index].type}`);
        },
        [navigate, rootPath, taxJurisdiction, importTypesForJurisdiction],
    );

    useEffect(() => {
        if ((selectedImportTypeTab === undefined || selectedImportTypeTab < 0 || importTypeFromParam !== importType) && importTypesForJurisdiction?.length) {
            navigate(`${rootPath}/${taxJurisdiction}/${importType}`, { replace: true });
        }
    }, [importTypesForJurisdiction, importType, importTypeFromParam, navigate, rootPath, selectedImportTypeTab, taxJurisdiction]);

    return {
        entityId,
        taxJurisdiction,
        importType,
        importTypes: importTypesForJurisdiction,
        selectedTaxJurisdictionTab,
        taxJurisdictionTabChanged,
        selectedImportTypeTab,
        importTypeTabChanged,
    };
};
