import { FunctionComponent, useCallback } from "react";
import { Box, Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { downloadEntityImportTemplate, downloadEntityImportTemplateWithData, ImportTypeResponse, useImportSummaries, useUploadEntityDataFile } from "../../api";
import { displayImportType, ImportStatus, ImportType } from "../../enums";
import { DownloadFunction, FileContainer } from "am-tax-fe-core";
import { DataImportControls } from "../../components/DataImportControls";
import { DataImportsGrid } from "../../components/DataImportsGrid";
import { EntityPageTemplate } from "./EntityPageTemplate";
import { displayTaxJurisdiction, taxJurisdictions } from "../../enums/TaxJurisdiction";
import { useImportTypeTabs } from "./useImportTypeTabs";

export const EntityDataImportsPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();

    const {
        entityId,
        taxJurisdiction,
        importType,
        importTypes,
        selectedTaxJurisdictionTab,
        taxJurisdictionTabChanged,
        selectedImportTypeTab,
        importTypeTabChanged,
    } = useImportTypeTabs("../dataImports");

    const importSummariesQuery = useImportSummaries({ clientId, entityId, importType });

    const uploadEntityDataFile = useUploadEntityDataFile();

    const downloadTemplateFn: DownloadFunction = useCallback(
        async progressCallback =>
            downloadEntityImportTemplate({
                clientId: clientId!,
                entityId: entityId!,
                importType,
                progressCallback,
            }),
        [clientId, entityId, importType],
    );

    const downloadDataFn: DownloadFunction = useCallback(
        async progressCallback =>
            downloadEntityImportTemplateWithData({
                clientId: clientId!,
                entityId: entityId!,
                importType,
                progressCallback,
            }),
        [clientId, entityId, importType],
    );

    const onFileAdded = useCallback(
        async (fileContainer: FileContainer) =>
            uploadEntityDataFile.mutateAsync({
                clientId: clientId!,
                entityId: entityId!,
                importType,
                fileContainer,
            }),
        [clientId, entityId, importType, uploadEntityDataFile],
    );

    if (!clientId || !entityId) {
        return "This page requires clientId and entityId to render.";
    }

    return (
        <EntityPageTemplate
            title={"Imports"}
            clientId={clientId}
            entityId={entityId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/dataImports/${taxJurisdiction}/${importType}`);
            }}
            gutter={
                <Tabs index={selectedTaxJurisdictionTab} onChange={taxJurisdictionTabChanged} mb={".5rem"} size={"lg"}>
                    <TabList borderBottom={"1px solid"} borderColor={"gray.100"}>
                        {taxJurisdictions.map((jurisdiction, index) => (
                            <Tab key={index} _selected={{ color: "blue.800", fontWeight: 600 }} color={"gray.400"} fontWeight={300} px={"1.5rem"}>
                                {displayTaxJurisdiction(jurisdiction)}
                            </Tab>
                        ))}
                    </TabList>
                    <TabIndicator mt="-2.5px" height="3px" bg="blue.500" borderRadius="1px" />
                </Tabs>
            }
        >
            <Tabs index={selectedImportTypeTab} variant={"solid-rounded"} isFitted onChange={importTypeTabChanged}>
                <TabList>
                    {importTypes.map((importTypeResponse: ImportTypeResponse, index) => (
                        <Tab key={index}>{displayImportType(importTypeResponse.type as ImportType)}</Tab>
                    ))}
                </TabList>
            </Tabs>
            <Box mt={"3rem"}>
                <DataImportControls
                    hasData={!!importSummariesQuery.data && importSummariesQuery.data.some(importSummary => importSummary.status == ImportStatus.Accepted)}
                    downloadTemplateFn={downloadTemplateFn}
                    downloadDataFn={downloadDataFn}
                    onFileAdded={onFileAdded}
                />
                <DataImportsGrid
                    data={importSummariesQuery.data}
                    clientId={clientId}
                    entityId={entityId}
                    importType={importType}
                    isLoading={importSummariesQuery.isFetching}
                />
            </Box>
        </EntityPageTemplate>
    );
};
