import { FC, ReactNode } from "react";
import { Box, Collapse, Flex, IconButton } from "@chakra-ui/react";
import { IconLayoutNavbarCollapseFilled, IconLayoutNavbarExpandFilled } from "@tabler/icons-react";
import { Page, PageProps } from "../../pages/Page";
import { PrimitiveAtom, useAtom } from "jotai/index";

type PageWithCollapsableHeaderProps = {
    showHeaderAtom: PrimitiveAtom<boolean>;
    header: ReactNode;
} & PageProps;

export const PageWithCollapsableHeader: FC<PageWithCollapsableHeaderProps> = ({ showHeaderAtom, header, children, ...pageProps }) => {
    const [showHeader, setShowHeader] = useAtom(showHeaderAtom);

    return (
        <Page p={0} {...pageProps}>
            <Flex direction={"column"}>
                <Box>
                    <Collapse in={showHeader}>{header}</Collapse>
                </Box>

                <Box p={"3rem"} position={"relative"}>
                    <Flex position={"absolute"} top={0} right={"3rem"}>
                        <Box
                            boxSize={"1rem"}
                            overflow={"hidden"}
                            position={"relative"}
                            sx={{
                                "&::before": {
                                    content: "''",
                                    position: "absolute",
                                    borderTopEndRadius: "50%",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    boxShadow: ".5rem -.5rem var(--chakra-colors-gray-50)",
                                },
                            }}
                        />
                        <Box bg={"gray.50"} padding={".25rem .75rem"} borderBottomRadius={".5rem"}>
                            <IconButton
                                variant={"naked"}
                                color={"blackAlpha.700"}
                                aria-label={"collapse"}
                                icon={showHeader ? <IconLayoutNavbarCollapseFilled /> : <IconLayoutNavbarExpandFilled />}
                                onClick={() => setShowHeader(!showHeader)}
                            />
                        </Box>
                        <Box
                            boxSize={"1rem"}
                            overflow={"hidden"}
                            position={"relative"}
                            sx={{
                                "&::before": {
                                    content: "''",
                                    position: "absolute",
                                    borderTopStartRadius: "50%",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    boxShadow: "-.5rem -.5rem var(--chakra-colors-gray-50)",
                                },
                            }}
                        />
                    </Flex>
                    {children}
                </Box>
            </Flex>
        </Page>
    );
};
