import { FC, Fragment } from "react";
import { Card, CardBody, Grid, Text, VStack } from "@chakra-ui/react";
import { KPISummary } from "../../api";

export const KpiCard: FC<{ kpis: KPISummary[] | undefined }> = ({ kpis }) => {
    return (
        <Card variant={"withShadow"}>
            <CardBody>
                <VStack h={"100%"} justifyContent={"center"}>
                    <Grid templateColumns="auto auto" gap={"2rem"} w={"100%"} alignItems={"baseline"}>
                        {kpis?.map((kpi, index) => (
                            <Fragment key={index}>
                                <Text fontSize={"1.5rem"} align={"right"} fontWeight={"bold"} sx={{ fontVariantNumeric: "tabular-nums" }}>
                                    {kpi.value}
                                </Text>
                                <Text fontSize={"1rem"} fontWeight={300}>
                                    {kpi.name}
                                </Text>
                            </Fragment>
                        ))}
                    </Grid>
                </VStack>
            </CardBody>
        </Card>
    );
};
