import { FC, useMemo, useState } from "react";
import { LeftNav as LeftNavCore, NavGroup, NavItem, NavItemProps, getLeftNavExpandedPref } from "am-tax-fe-core";
import {
    IconArrowBounce,
    IconBuildingBridge,
    IconBuildingEstate,
    IconBuildingSkyscraper,
    IconCalculator,
    IconClipboardData,
    IconHome,
    IconHomeSearch,
    IconLetterK,
    IconReport,
    IconSettings,
    IconTransferIn,
    IconUsers,
    IconUsersGroup,
} from "@tabler/icons-react";
import { useClientFromParams } from "../hooks/useClientFromParams";
import { useEntityFromParams } from "../hooks/useEntityFromParams";
import { ImportType } from "../enums";
import { Box, Heading } from "@chakra-ui/react";
import { useCurrentUser } from "../api";

export const LeftNav: FC = () => {
    const { clientId, clientQuery } = useClientFromParams();
    const client = clientQuery.data || null;
    const { entityId, entityQuery } = useEntityFromParams(clientId);
    const entity = entityQuery.data || null;

    const currentUser = useCurrentUser().data;

    const items = useMemo(() => {
        const items: Array<NavGroup | NavItemProps> = [
            {
                to: "/myClients",
                leftIcon: <IconHomeSearch size="1rem" />,
                text: "My Clients",
            },
        ];

        if (clientId) {
            items.push({
                isGroup: true,
                groupColor: "blue.400",
                items: [
                    <Heading
                        variant={"info"}
                        p={".5rem 1rem .5rem 1rem"}
                        bg={"blue.600"}
                        size={"sm"}
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        maxWidth={"100%"}
                        color={"whiteAlpha.700"}
                    >
                        {client?.name}&nbsp;
                    </Heading>,
                    {
                        leftIcon: <IconHome size="1rem" />,
                        to: `/client/${clientId}/clientSummary`,
                        text: "Client Summary",
                        activeColor: "blue.400",
                    },
                    {
                        leftIcon: <IconReport size="1rem" />,
                        to: `/client/${clientId}/clientReports`,
                        text: "Client Reports",
                        activeColor: "blue.400",
                    },
                ],
            } as NavGroup);

            items.push({
                isGroup: true,
                groupColor: "cherry.400",
                items: [
                    entity ? (
                        <Heading
                            variant={"info"}
                            p={".5rem 1rem .5rem 1rem"}
                            bg={"cherryAlpha.700"}
                            size={"sm"}
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            maxWidth={"100%"}
                            color={"whiteAlpha.700"}
                        >
                            {entity?.name1}&nbsp;{entity?.name2}&nbsp;{entity?.name3}
                        </Heading>
                    ) : (
                        {
                            to: `/client/${clientId}/selectEntity`,
                            leftIcon: <IconArrowBounce size="1rem" />,
                            text: "Select Entity",
                            activeColor: "cherry.400",
                            hoverColor: "cherryAlpha.600",
                        }
                    ),
                    {
                        isDisabled: !entityId,
                        to: `/client/${clientId}/entity/${entityId}/dataImports`,
                        leftIcon: <IconTransferIn size="1rem" />,
                        text: "Imports",
                        activeColor: "cherry.400",
                        hoverColor: "cherryAlpha.600",
                    },
                    {
                        isDisabled: !entityId,
                        to: `/client/${clientId}/entity/${entityId}/reviewData`,
                        leftIcon: <IconClipboardData size="1rem" />,
                        text: "Review Data",
                        activeColor: "cherry.400",
                        hoverColor: "cherryAlpha.600",
                    },
                    {
                        isDisabled: !entityId,
                        to: `/client/${clientId}/entity/${entityId}/calculations`,
                        leftIcon: <IconCalculator size="1rem" />,
                        text: "Calculations",
                        activeColor: "cherry.400",
                        hoverColor: "cherryAlpha.600",
                        end: true,
                    },
                    {
                        isDisabled: !entityId,
                        to: `/client/${clientId}/entity/${entityId}/entityReports`,
                        leftIcon: <IconReport size="1rem" />,
                        text: "Entity Reports",
                        activeColor: "cherry.400",
                        hoverColor: "cherryAlpha.600",
                        end: true,
                    },
                    {
                        isDisabled: !entityId,
                        to: `/client/${clientId}/entity/${entityId}/k1Packages`,
                        leftIcon: <IconLetterK size="1rem" />,
                        text: "K-1 Packages",
                        activeColor: "cherry.400",
                        hoverColor: "cherryAlpha.600",
                        end: true,
                    },
                ],
            } as NavGroup);

            items.push({
                isGroup: true,
                groupColor: "blue.400",
                groupHeading: "Client Setup",
                items: [
                    {
                        to: `/clientAdmin/${clientId}/configuration`,
                        leftIcon: <IconSettings size="1rem" />,
                        text: "Configuration",
                    },
                    { to: `/clientAdmin/${clientId}/users`, leftIcon: <IconUsers size="1rem" />, text: "Manage Users" },
                    {
                        to: `/clientAdmin/${clientId}/dataImports/${ImportType.Entity}`,
                        leftIcon: <IconBuildingEstate size="1rem" />,
                        text: "Entities",
                    },
                    {
                        to: `/clientAdmin/${clientId}/dataImports/${ImportType.Partner}`,
                        leftIcon: <IconBuildingBridge size="1rem" />,
                        text: "Partners",
                    },
                    {
                        to: `/clientAdmin/${clientId}/dataImports/${ImportType.FederalK1Lines}`,
                        leftIcon: <IconLetterK size="1rem" />,
                        text: "Fed K1 Lines",
                    },
                    {
                        to: `/clientAdmin/${clientId}/dataImports/${ImportType.StateActivation}`,
                        leftIcon: <IconBuildingSkyscraper size="1rem" />,
                        text: "State Activation",
                    },
                ],
            } as NavGroup);
        }

        return items;
    }, [client, clientId, entity, entityId]);

    const [expanded, setExpandedState] = useState(getLeftNavExpandedPref());

    const itemsFooter = useMemo(() => {
        if (currentUser?.isSystemAdmin) {
            return (
                <Box>
                    <NavItem
                        show={currentUser?.isSystemAdmin}
                        to={"/admin"}
                        leftIcon={<IconUsersGroup />}
                        text={expanded ? "Admin Dashboard" : ""}
                        itemKey={"admin"}
                        isExpanded={expanded}
                        activeColor={"aqua.500"}
                    />
                </Box>
            );
        }
    }, [currentUser, expanded]);

    return <LeftNavCore logo={{ type: "global" }} appName={"Slipstream"} items={items} itemsFooter={itemsFooter} onSizeChange={setExpandedState} />;
};
