import BgImage from "../../assets/images/586080879.jpg";
import { LoginPage, MsalLoginForm, AMTaxLogoGlobalWhite } from "am-tax-fe-core";

export const UnauthenticatedLandingPage = () => {
    return (
        <LoginPage appName="Slipstream" bgImage={`url(${BgImage})`} logoSvg={AMTaxLogoGlobalWhite}>
            <MsalLoginForm />
        </LoginPage>
    );
};
