import { FC, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, HStack, Icon, Link, Text } from "@chakra-ui/react";
import { ICellRendererParams } from "ag-grid-community";
import { AgGrid, AgGridProps, Button, DownloadButton, formatTimestampStr, IconButton, useConfirm, useToast } from "am-tax-fe-core";
import { downloadImportFile, downloadImportFileForReview, ImportSummary, useDeleteImportSummary, usePerformImportAction } from "../../api";
import { ImportStatusIcon } from "../ImportStatusIcon";
import { IconDownload, IconTrash } from "@tabler/icons-react";
import { displayImportStatus, ImportAction, ImportStatus, ImportType } from "../../enums";

type DataImportsGridProps = {
    clientId: string;
    entityId?: string;
    importType: ImportType;
    data?: ImportSummary[];
} & AgGridProps<ImportSummary>;

export const DataImportsGrid: FC<DataImportsGridProps> = ({ data, importType, clientId, entityId, ...agGridProps }) => {
    const toast = useToast();
    const performImportAction = usePerformImportAction(entityId);
    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete", prompt: "Are you sure you want to delete?" });

    const handleActionClick = useCallback(
        (importSummaryId: string, importAction: ImportAction) => {
            return async () => {
                await performImportAction.mutateAsync({
                    clientId,
                    importSummaryId,
                    importAction,
                });
            };
        },
        [clientId, performImportAction],
    );

    const getActionButton = useCallback(
        (importSummary: ImportSummary) => {
            // chakra buttons are flex by default, but the text overflows when there is not enough space for the button.
            // manually set the below buttons to be block elements and added rules for overflow scenarios.
            switch (importSummary!.status) {
                case ImportStatus.Ready:
                    return (
                        <Button
                            display={"block"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            title={"Submit"}
                            color={"green.500"}
                            variant={"ghost"}
                            onClick={handleActionClick(importSummary!.id, ImportAction.Submit)}
                        >
                            Submit
                        </Button>
                    );
                case ImportStatus.Complete:
                    return (
                        <Button
                            display={"block"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            title={"Restore"}
                            color={"gray.400"}
                            variant={"ghost"}
                            onClick={handleActionClick(importSummary!.id, ImportAction.Restore)}
                        >
                            Restore
                        </Button>
                    );
                case ImportStatus.NotStarted:
                    return (
                        <Button
                            display={"block"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            title={"Validate"}
                            variant={"ghost"}
                            onClick={handleActionClick(importSummary!.id, ImportAction.Validate)}
                        >
                            Validate
                        </Button>
                    );
                case ImportStatus.Processing:
                    return (
                        <Button display={"block"} overflow={"hidden"} textOverflow={"ellipsis"} variant={"ghost"} isLoading={true} loadingText={"Processing"} />
                    );
                case ImportStatus.Accepted:
                    return (
                        <Text fontSize={"1rem"} fontWeight={"bold"} paddingLeft={"1rem"}>
                            Accepted
                        </Text>
                    );
                case ImportStatus.Error:
                    return (
                        <DownloadButton
                            display={"block"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            title={"Review Errors"}
                            variant={"ghost"}
                            color={"red.500"}
                            downloadFn={async progressCallback => {
                                try {
                                    return await downloadImportFileForReview({
                                        clientId,
                                        importSummaryId: importSummary!.id,
                                        progressCallback,
                                    });
                                } catch (e) {
                                    toast({
                                        title: "Error",
                                        description: "The file could not be downloaded for review",
                                        status: "error",
                                        duration: 5000,
                                        isClosable: true,
                                    });
                                    throw e;
                                }
                            }}
                        >
                            Review Errors
                        </DownloadButton>
                    );
            }
        },
        [clientId, handleActionClick, toast],
    );

    const deleteImportSummary = useDeleteImportSummary(entityId, importType);

    return (
        <>
            <ConfirmDialog />
            <AgGrid
                rowData={data}
                columnDefs={[
                    {
                        field: "fileName",
                        minWidth: 450,
                        cellRenderer: ({ data: importSummary }: ICellRendererParams) => (
                            <HStack>
                                <Box>
                                    <Link as={RouterLink} textDecoration={"underline"} to={`viewImportData/${importSummary.id}`}>
                                        {importSummary?.fileName}
                                    </Link>
                                </Box>
                            </HStack>
                        ),
                    },
                    {
                        headerName: "Uploaded By",
                        field: "uploadedBy",
                        valueGetter: ({ data: importSummary }) => `${importSummary?.uploadedBy?.firstName} ${importSummary?.uploadedBy?.lastName}`,
                    },
                    {
                        headerName: "Date",
                        field: "uploadedOn",
                        sort: "desc",
                        cellRenderer: ({ data: importSummary }: ICellRendererParams) => formatTimestampStr(importSummary?.uploadedOn),
                    },
                    {
                        field: "status",
                        cellRenderer: ({ data: importSummary }: ICellRendererParams) => (
                            <HStack>
                                <Box position="relative" boxSize={"1rem"}>
                                    <Box position="absolute" top="-13px">
                                        <ImportStatusIcon status={importSummary?.status} boxSize={"1rem"} />
                                    </Box>
                                </Box>
                                <Box>{displayImportStatus(importSummary?.status)}</Box>
                            </HStack>
                        ),
                    },
                    {
                        headerName: "Action",
                        cellRenderer: ({ data: importSummary }: ICellRendererParams) => {
                            return (
                                <HStack alignItems={"center"} h={"100%"}>
                                    <DownloadButton
                                        icon={<Icon as={IconDownload} />}
                                        variant={"ghost"}
                                        bg={"transparent"}
                                        downloadFn={async progressCallback => {
                                            try {
                                                return await downloadImportFile({
                                                    clientId,
                                                    importSummaryId: importSummary!.id,
                                                    progressCallback,
                                                });
                                            } catch (e) {
                                                toast({
                                                    title: "Error",
                                                    description: "The file could not be downloaded",
                                                    status: "error",
                                                    duration: 5000,
                                                    isClosable: true,
                                                });
                                                throw e;
                                            }
                                        }}
                                    />
                                    <IconButton
                                        icon={<Icon as={IconTrash} />}
                                        variant={"ghost"}
                                        aria-label={"Delete"}
                                        onClick={async () => {
                                            if (await confirm()) {
                                                await deleteImportSummary.mutateAsync({
                                                    clientId,
                                                    importSummaryId: importSummary!.id,
                                                });
                                            }
                                        }}
                                    />
                                    {getActionButton(importSummary)}
                                </HStack>
                            );
                        },
                    },
                ]}
                {...agGridProps}
            />
        </>
    );
};
