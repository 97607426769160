import { FC } from "react";
import { Box, HStack, Icon, IconButton } from "@chakra-ui/react";
import { ICellRendererParams } from "ag-grid-community";
import { AgGrid, DownloadButton, formatTimestampStr, useConfirm, useToast } from "am-tax-fe-core";
import { downloadK1Package, useDeleteK1Package, useK1PackageHistory } from "../../api";
import { IconDownload, IconTrash } from "@tabler/icons-react";

type ReportHistoryGridProps = {
    clientId: string;
    entityId: string;
};

export const K1PackagesHistoryGrid: FC<ReportHistoryGridProps> = ({ clientId, entityId }) => {
    const toast = useToast();
    const k1PackageHistoryQuery = useK1PackageHistory({ clientId, entityId });
    const k1PackageHistory = k1PackageHistoryQuery.data;
    const deleteK1Package = useDeleteK1Package(entityId);
    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete Package", prompt: "Are you sure you want to delete this package?" });

    return (
        <>
            <ConfirmDialog />
            <AgGrid
                rowData={k1PackageHistory}
                columnDefs={[
                    {
                        headerName: "Package Name",
                        field: "name",
                        sort: "asc",
                    },
                    {
                        headerName: "Generated By",
                        field: "user",
                        valueGetter: ({ data: k1Package }) => `${k1Package!.user.firstName} ${k1Package!.user.lastName}`,
                    },
                    {
                        headerName: "Started",
                        field: "startDate",
                        sort: "desc",
                        cellRenderer: ({ data: k1Package }: ICellRendererParams) => formatTimestampStr(k1Package?.startDate.toString()),
                    },
                    {
                        headerName: "Finished",
                        field: "endDate",
                        cellRenderer: ({ data: k1Package }: ICellRendererParams) => formatTimestampStr(k1Package?.endDate.toString()),
                    },
                    {
                        headerName: "Actions",
                        cellRenderer: ({ data: k1Package }: ICellRendererParams) => (
                            <HStack>
                                <Box>
                                    <DownloadButton
                                        icon={<Icon as={IconDownload} />}
                                        aria-label={"download"}
                                        variant={"ghost"}
                                        bg={"transparent"}
                                        downloadFn={async progressCallback => {
                                            try {
                                                return await downloadK1Package({
                                                    clientId,
                                                    packageId: k1Package.id,
                                                    progressCallback,
                                                });
                                            } catch (e) {
                                                toast({
                                                    title: "Error",
                                                    description: "The package could not be downloaded",
                                                    status: "error",
                                                    duration: 5000,
                                                    isClosable: true,
                                                });
                                                throw e;
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <IconButton
                                        icon={<Icon as={IconTrash} />}
                                        aria-label={"delete"}
                                        variant={"ghost"}
                                        bg={"transparent"}
                                        onClick={async () => {
                                            await confirm();
                                            try {
                                                await deleteK1Package.mutateAsync({
                                                    clientId,
                                                    packageId: k1Package.id,
                                                });
                                                toast({
                                                    title: "Deleted",
                                                    description: "Package Deleted",
                                                    status: "success",
                                                    duration: 3000,
                                                    isClosable: true,
                                                });
                                            } catch (e) {
                                                toast({
                                                    title: "Error",
                                                    description: "Package could not be deleted",
                                                    status: "error",
                                                    duration: 5000,
                                                    isClosable: true,
                                                });
                                            }
                                        }}
                                    />
                                </Box>
                            </HStack>
                        ),
                    },
                ]}
            />
        </>
    );
};
