export enum RoundingPartnerRule {
    LargestPartner = 1,
    GpPartner = 2,
    FirstPartner = 3,
    LastPartner = 4,
}

export const RoundingPartners = Object.values(RoundingPartnerRule).filter(v => !isNaN(v as RoundingPartnerRule)) as RoundingPartnerRule[];

type CalculationStatusDetails = { title: string };

export const RoundingPartnerRuleLookup: Record<RoundingPartnerRule, CalculationStatusDetails> = {
    [RoundingPartnerRule.LargestPartner]: { title: "Largest Partner" },
    [RoundingPartnerRule.GpPartner]: { title: "GP Partner" },
    [RoundingPartnerRule.FirstPartner]: { title: "First Partner" },
    [RoundingPartnerRule.LastPartner]: { title: "Last Partner" },
};

export const displayRoundingPartnerRule = (rule: RoundingPartnerRule) => {
    return RoundingPartnerRuleLookup[rule].title;
};
