import React from "react";
import ReactDOM from "react-dom/client";
import { onlineManager } from "@tanstack/react-query";
import { theme } from "./theme";
import { AppConfigurationProvider, bool, defaultLocalizationStorageImpl, Fonts, localizationService } from "am-tax-fe-core";
import { ChakraProvider } from "@chakra-ui/react";
import { Middleware } from "openapi-typescript-fetch";
import { configure } from "./api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UnauthenticatedLandingPage } from "./pages/UnauthenticatedLandingPage";
import { PageRoutes } from "./pages";

function configureHttpClient(baseUrl: string, getAccessToken: () => Promise<string>) {
    const msalMiddleware: Middleware = async (url, init, next) => {
        if (!bool(import.meta.env.VITE_BYPASS_AUTH)) {
            const accessToken = await getAccessToken();
            init.headers.append("Authorization", `Bearer ${accessToken}`);
        }
        return next(url, init);
    };

    configure(baseUrl, [msalMiddleware]);
}

(async function () {
    if (bool(import.meta.env.VITE_BYPASS_AUTH) || bool(import.meta.env.VITE_MOCK_PARTIAL_BACKEND) || bool(import.meta.env.VITE_MOCK_ENTIRE_BACKEND)) {
        // this probably seems backwards, but we want to tell react query to act like it is online even if it is offline
        onlineManager.setOnline(true);

        const setup = await import("./api/msw/setupForBrowser");
        const { worker } = setup;
        await worker.start();
    }

    // we need to initialize the localization service before we render the app
    await localizationService.init(defaultLocalizationStorageImpl);

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <React.StrictMode>
            <ChakraProvider theme={theme} resetCSS>
                <Fonts />
                <AppConfigurationProvider
                    envJsonUrl={"/env.json"}
                    byPassAuth={bool(import.meta.env.VITE_BYPASS_AUTH) || bool(import.meta.env.VITE_MOCK_ENTIRE_BACKEND)}
                    unauthenticatedLandingPage={<UnauthenticatedLandingPage />}
                    onApiContextInit={configureHttpClient}
                    enableAppInsights={bool(import.meta.env.VITE_ENABLE_APP_INSIGHTS)}
                    version={{
                        ver: import.meta.env.VITE_BUILD_COMMIT,
                        build: import.meta.env.VITE_BUILD_NUMBER,
                    }}
                    queryClientDefaultOptions={{
                        queries: {
                            retry: 0,
                            gcTime: 8 * 1000,
                            staleTime: 4 * 1000,
                            refetchOnWindowFocus: false,
                            refetchOnReconnect: false,
                        },
                    }}
                >
                    <PageRoutes />
                    {bool(import.meta.env.VITE_ENABLE_REACT_QUERY_DEV_TOOLS) && <ReactQueryDevtools initialIsOpen={false} />}
                </AppConfigurationProvider>
            </ChakraProvider>
        </React.StrictMode>,
    );
})();
