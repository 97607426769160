import { FC, PropsWithChildren } from "react";
import { Box, Card, CardBody, Heading, Progress, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { showClientSummaryAtom } from "../../atoms";
import { PageWithCollapsableHeader } from "../../components/PageWithCollapsableHeader";
import { EntityHierarchyWidget, NodeClickedEvent } from "../../components/EntityHierarchyWidget";
import { useClientKPIs } from "../../api";
import { KpiCard } from "../../components/KpiCard";

export type ClientPageTemplateProps = PropsWithChildren<{ title: string; clientId: string; nodeClicked: NodeClickedEvent }>;

export const ClientPageTemplate: FC<ClientPageTemplateProps> = ({ title, clientId, nodeClicked, children }) => {
    const clientKpisQuery = useClientKPIs({ clientId });
    return (
        <PageWithCollapsableHeader
            title={title}
            showHeaderAtom={showClientSummaryAtom}
            header={
                <SimpleGrid columns={3} spacing={"1rem"} p={"3rem"} bg={"gray.50"}>
                    <Card variant={"withShadow"}>
                        <CardBody>
                            <VStack alignItems={"center"}>
                                <Heading fontWeight={"bold"}>Data Gathered</Heading>
                                <Text fontSize={"1.5rem"} fontWeight={"bold"}>
                                    59%
                                </Text>
                                <Box w={"100%"}>
                                    <Progress value={59} />
                                </Box>
                            </VStack>
                        </CardBody>
                    </Card>
                    <KpiCard kpis={clientKpisQuery.data} />
                    <EntityHierarchyWidget clientId={clientId} nodeClicked={nodeClicked} />
                </SimpleGrid>
            }
        >
            {children}
        </PageWithCollapsableHeader>
    );
};
