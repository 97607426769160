import { FC } from "react";
import { EntityHierarchyViewer } from "../EntityHierarchyViewer";
import { Card, CardBody, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useEntityHierarchy } from "../../api";

export type NodeClickedEvent = (entityId: string) => void;

export type EntityHierarchyWidgetProps = { clientId: string; entityId?: string; nodeClicked?: NodeClickedEvent };

export const EntityHierarchyWidget: FC<EntityHierarchyWidgetProps> = ({ clientId, entityId, nodeClicked }) => {
    const entityHierarchyQuery = useEntityHierarchy({ clientId });
    const entityHierarchy = entityHierarchyQuery.data;

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Card overflow={"hidden"} variant={"withShadow"}>
                <CardBody p={0}>
                    <EntityHierarchyViewer
                        entityHierarchy={entityHierarchy}
                        w={"100%"}
                        h={"100%"}
                        selectedEntityId={entityId}
                        useMiniStyles={true}
                        onEntityClicked={nodeClicked}
                        onExpand={onOpen}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={0}>
                        <EntityHierarchyViewer
                            entityHierarchy={entityHierarchy}
                            w={"100%"}
                            h={`calc(100vh - 6rem)`}
                            selectedEntityId={entityId}
                            onEntityClicked={entityId => {
                                if (nodeClicked) {
                                    nodeClicked(entityId);
                                }
                                onClose();
                            }}
                        />
                    </ModalBody>
                    <ModalCloseButton onClick={onClose} />
                </ModalContent>
            </Modal>
        </>
    );
};
