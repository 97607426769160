import { FC, useMemo } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { registerGlobal403Handler } from "am-tax-fe-core";

import { AdminDashboardPage } from "./AdminDashboardPage";
import { MyClientsPage } from "./MyClientsPage";
import { ClientSummaryPage } from "./ClientSummaryPage";
import { UnauthorizedPage } from "./UnauthorizedPage";
import { NotFoundPage } from "./NotFoundPage";
import { EntityDataImportsPage } from "./EntityDataImportsPage";
import { ClientDataImportsPage } from "./ClientDataImportsPage";
import { ViewImportDataModal } from "./ViewImportDataModal";
import { withOutlet } from "../utils";
import { SelectEntityPage } from "./SelectEntityPage";
import { EntityReportsPage } from "./EntityReportsPage";
import { ReviewDataPage } from "./ReviewDataPage";
import { CalculationsPage } from "./CalculationsPage";
import { K1PackagesPage } from "./K1PackagesPage";
import { ClientConfigurationPage } from "./ClientConfigurationPage";
import { UsersPage } from "./UsersPage";
import { ClientReportsPage } from "./ClientReportsPage";
import ApplicationLayout from "./ApplicationLayout";
import { AddClientPage } from "./AddClientPage";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ApplicationLayout />}>
            <Route path="admin">
                <Route path="" element={<AdminDashboardPage />} />
                <Route path="addClient" element={<AddClientPage />} />
            </Route>
            <Route index element={<Navigate to="myClients" replace />} />
            <Route path="myClients" element={<MyClientsPage />} />
            <Route path="client/:clientId">
                <Route path="clientSummary/:importType?" element={<ClientSummaryPage />} />
                <Route path="clientReports/:importType?" element={<ClientReportsPage />} />
                <Route path="selectEntity" element={<SelectEntityPage />} />
                <Route path="entity/:entityId">
                    <Route path="dataImports/:taxJurisdiction?/:importType?" {...withOutlet(<EntityDataImportsPage />)}>
                        <Route path="viewImportData/:importSummaryId" element={<ViewImportDataModal />} />
                    </Route>
                    <Route path="reviewData/:taxJurisdiction?/:importType?" element={<ReviewDataPage />} />
                    <Route path="calculations" element={<CalculationsPage />} />
                    <Route path="entityReports" element={<EntityReportsPage />} />
                    <Route path="k1Packages" element={<K1PackagesPage />} />
                </Route>
            </Route>
            <Route path="clientAdmin/:clientId">
                <Route path="dataImports/:importType" {...withOutlet(<ClientDataImportsPage />)}>
                    <Route path="viewImportData/:importSummaryId" element={<ViewImportDataModal />} />
                </Route>
                <Route path="configuration" element={<ClientConfigurationPage />} />
                <Route path="users" element={<UsersPage />} />
            </Route>
            <Route path="Unauthorized" element={<UnauthorizedPage />} />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Route>,
    ),
);

export const PageRoutes: FC = () => {
    useMemo(() => {
        registerGlobal403Handler(() => {
            router.navigate("/unauthorized");
        });
    }, []);

    return <RouterProvider router={router} />;
};
