import { FC, PropsWithChildren } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Page as CorePage, PageProps as CorePageProps } from "am-tax-fe-core";
import { useLocation, useNavigate } from "react-router-dom";
import { useClientFromParams } from "../hooks/useClientFromParams";
import { useEntityFromParams } from "../hooks/useEntityFromParams";
import { getEntityName } from "../utils";

export type PageProps = PropsWithChildren<Omit<CorePageProps, "location">>;

export const Page: FC<PageProps> = ({ topNavFooter, children, ...pageProps }) => {
    const location = useLocation();
    const { clientId, clientQuery } = useClientFromParams();
    const client = clientQuery.data;
    const { entityQuery } = useEntityFromParams(clientId);
    const entity = entityQuery.data;

    const navigate = useNavigate();

    return (
        <CorePage
            location={location}
            topNavFooter={
                topNavFooter ? (
                    topNavFooter
                ) : client?.name ? (
                    <Breadcrumb fontSize={"lg"}>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                textDecoration={entity ? "underline" : "none"}
                                onClick={() => {
                                    navigate(`/client/${clientId}/clientSummary`);
                                }}
                            >
                                {client?.name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        {entity && (
                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink>{getEntityName(entity)}</BreadcrumbLink>
                            </BreadcrumbItem>
                        )}
                    </Breadcrumb>
                ) : null
            }
            {...pageProps}
        >
            {children}
        </CorePage>
    );
};
