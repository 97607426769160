import { FC } from "react";
import { Link } from "@chakra-ui/react";
import { ICellRendererParams } from "ag-grid-community";
import { AgGrid, AgGridProps, formatTimestampStr } from "am-tax-fe-core";
import { ImportSummary, useEntityCalculationSummary } from "../../api";

import { displayImportType } from "../../enums";
import { NavLink } from "react-router-dom";

type ReportHistoryGridProps = {
    clientId: string;
    entityId: string;
} & AgGridProps<ImportSummary>;

export const CalculationInputsGrid: FC<ReportHistoryGridProps> = ({ clientId, entityId, ...agGridProps }) => {
    const entityCalculationSummaryQuery = useEntityCalculationSummary({ clientId, entityId });
    const entityCalculationSummary = entityCalculationSummaryQuery.data || null;

    return (
        <AgGrid
            rowData={entityCalculationSummary?.components}
            columnDefs={[
                {
                    headerName: "Import Type",
                    field: "type",
                    sort: "asc",
                    valueGetter: ({ data: importSummary }) => displayImportType(importSummary!.type),
                    cellRenderer: ({ data: importSummary }: ICellRendererParams) => (
                        <Link as={NavLink} to={`../dataImports/${importSummary?.type}`} textDecoration={"underline"}>
                            {displayImportType(importSummary!.type)}
                        </Link>
                    ),
                },
                {
                    headerName: "Imported File Name",
                    field: "fileName",
                    valueGetter: ({ data: importSummary }) => `${importSummary!.fileName}`,
                },
                {
                    headerName: "Last Imported On",
                    field: "uploadedOn",
                    cellRenderer: ({ data: importSummary }: ICellRendererParams) => formatTimestampStr(importSummary!.uploadedOn.toString()),
                },
                {
                    headerName: "Last Imported By",
                    field: "uploadedBy",
                    valueGetter: ({ data: importSummary }) => `${importSummary!.uploadedBy.firstName} ${importSummary!.uploadedBy.lastName}`,
                },
            ]}
            {...agGridProps}
        />
    );
};
