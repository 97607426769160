import { FC, useCallback } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { ICellRendererParams } from "ag-grid-community";
import { AgGrid, Button, formatTimestampStr } from "am-tax-fe-core";
import { CalculationHistoryResponse, useEntityCalculationSummary, usePerformCalculationAction } from "../../api";

import { CalculationStatus, displayCalculationStatus } from "../../enums/CalculationStatus";
import { ImportStatusIcon } from "../../components/ImportStatusIcon";
import { CalculationAction } from "../../enums/CalculationAction";

type ReportHistoryGridProps = {
    clientId: string;
    entityId: string;
};

export const CalculationHistoryGrid: FC<ReportHistoryGridProps> = ({ clientId, entityId }) => {
    const entityCalculationSummaryQuery = useEntityCalculationSummary({ clientId, entityId });
    const entityCalculationSummary = entityCalculationSummaryQuery.data || null;
    const performCalculationAction = usePerformCalculationAction(entityId);

    const handleActionClick = useCallback(
        (calculationHistoryId: string, calculationAction: CalculationAction) => {
            return async () => {
                await performCalculationAction.mutateAsync({
                    clientId,
                    entityId,
                    calculationHistoryId,
                    calculationAction,
                });
            };
        },
        [clientId, entityId, performCalculationAction],
    );

    const getActionButton = useCallback(
        (calculationResponse: CalculationHistoryResponse) => {
            switch (calculationResponse!.status) {
                case CalculationStatus.Complete:
                    return (
                        <Button
                            color={"green.500"}
                            variant={"ghost"}
                            onClick={handleActionClick(calculationResponse!.id, CalculationAction.Submit)}
                            isLoading={performCalculationAction.isPending}
                        >
                            Submit
                        </Button>
                    );
                case CalculationStatus.Processing:
                    return <Button variant={"ghost"} isLoading={true} loadingText={"Processing"} />;
                case CalculationStatus.Accepted:
                    return (
                        <Text fontSize={"1rem"} fontWeight={"bold"} paddingLeft={"1rem"}>
                            Accepted
                        </Text>
                    );
                case CalculationStatus.Errors:
                    return (
                        <Text fontSize={"1rem"} fontWeight={"bold"} paddingLeft={"1rem"} color={"red.500"}>
                            Errors
                        </Text>
                    );
            }
        },
        [handleActionClick, performCalculationAction.isPending],
    );

    return (
        <AgGrid
            rowData={entityCalculationSummary?.history}
            columnDefs={[
                {
                    headerName: "Run By",
                    field: "createdBy",
                    valueGetter: ({ data: calculationResponse }) => `${calculationResponse!.createdBy.firstName} ${calculationResponse!.createdBy.lastName}`,
                },
                {
                    headerName: "Started",
                    field: "startDate",
                    cellRenderer: ({ data: calculationResponse }: ICellRendererParams) => formatTimestampStr(calculationResponse!.startDate.toString()),
                },
                {
                    headerName: "Completed",
                    field: "endDate",
                    sort: "desc",
                    cellRenderer: ({ data: calculationResponse }: ICellRendererParams) => formatTimestampStr(calculationResponse!.endDate?.toString()),
                },
                {
                    field: "status",
                    cellRenderer: ({ data: calculationResponse }: ICellRendererParams) => (
                        <HStack>
                            <Box position="relative" boxSize={"1rem"}>
                                <Box position="absolute" top="-13px">
                                    <ImportStatusIcon status={calculationResponse?.status} boxSize={"1rem"} />
                                </Box>
                            </Box>
                            <Box>{displayCalculationStatus(calculationResponse?.status)}</Box>
                        </HStack>
                    ),
                },
                {
                    headerName: "Accepted On",
                    field:"acceptedOn",
                    cellRenderer: ({ data: calculationResponse }: ICellRendererParams) => formatTimestampStr(calculationResponse!.acceptedOn?.toString()),
                },
                {
                    headerName: "Accepted By",
                    field:"acceptedBy",
                    valueGetter: ({ data: calculationResponse }) => `${calculationResponse!.acceptedBy?.firstName || ""} ${calculationResponse?.acceptedBy?.lastName || ""}`,
                },
                {
                    headerName: "Action",
                    cellRenderer: ({ data: calculationResponse }: ICellRendererParams) => {
                        return (
                            <HStack alignItems={"center"} h={"100%"}>
                                {getActionButton(calculationResponse)}
                            </HStack>
                        );
                    },
                },
            ]}
        />
    );
};
