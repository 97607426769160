import { FC } from "react";
import { useEntityDataByImportType } from "../../api";
import { ImportType } from "../../enums";
import { PageLoading } from "am-tax-fe-core";
import { WorksheetViewer } from "../WorksheetViewer";

type ClientImportDataViewer = {
    clientId: string;
    entityId: string;
    importType: ImportType;
};

export const EntityImportDataViewer: FC<ClientImportDataViewer> = ({ clientId, entityId, importType }) => {
    const clientDataQuery = useEntityDataByImportType({ clientId, entityId, importType });
    if (clientDataQuery.isLoading || !clientDataQuery.data) {
        return <PageLoading text={"Loading Data..."} />;
    } else {
        return <WorksheetViewer worksheetData={clientDataQuery.data} />;
    }
};
