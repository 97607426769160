import { FunctionComponent, useMemo, useState } from "react";
import useStateRef from "react-usestateref";
import { useParams } from "react-router-dom";
import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    ScaleFade,
    Select,
    Spacer,
    Text,
} from "@chakra-ui/react";
import { createColumnHelper, InitialTableState } from "@tanstack/react-table";
import { ClientUser, useAddOrUpdateUsersOnClient, useClientUsers, useDeleteUserFromClient, useRoles, useSearchUsersQueryFn } from "../../api";
import { AutoCompleteMulti, DataTable, DownloadButton, formatTimestampStr, Option, Shimmer, useConfirm, useToast } from "am-tax-fe-core";
import { IconDownload, IconEdit, IconEditOff, IconPlus, IconTrash } from "@tabler/icons-react";
import debounce from "lodash.debounce";
import { ScaleLoader } from "react-spinners";

import { Page } from "../Page";

export const UsersPage: FunctionComponent = () => {
    const { clientId: clientIdParam } = useParams();
    const clientId = clientIdParam || "";
    const usersQuery = useClientUsers(clientId);
    const rolesQuery = useRoles();
    const toast = useToast();

    const userEmailsSet = useMemo(() => new Set(usersQuery.data?.map(user => user.user.email.toLowerCase()) ?? []), [usersQuery.data]);

    // ---- Setup User Search ----
    const searchUsersQueryFn = useSearchUsersQueryFn();
    const [userAutoCompleteOptions, setUserAutoCompleteOptions] = useState<Option<string>[]>([]);
    const searchUsers = debounce((searchText: string, callback): void => {
        searchUsersQueryFn({ searchText }).then(users => {
            const userSearchResults = users.map(user => {
                const alreadyThere = userEmailsSet.has(user.email.toLowerCase());

                return {
                    after: alreadyThere && <Text>Already Added</Text>,
                    isDisabled: alreadyThere,
                    value: user.id,
                    label: `${user.firstName} ${user.lastName} - ${user.email}`,
                };
            });
            callback(userSearchResults);
        });
    }, 250);

    const onUserSelected = async (options: Option<string>[]) => {
        setUserAutoCompleteOptions(options);
    };

    // ---- Setup Add User ----
    const addOrUpdateUserOnClientQuery = useAddOrUpdateUsersOnClient();
    const addUsers = async () => {
        const numUsers = userAutoCompleteOptions.length;

        await addOrUpdateUserOnClientQuery.mutateAsync({
            clientId,
            userRoles: userAutoCompleteOptions.map(option => ({
                userId: option.value,
            })),
        });
        setUserAutoCompleteOptions([]);
        toast({
            title: "Success",
            description: `${numUsers} users added to client. Please assign roles to users.`,
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    // ---- Setup Delete User ----
    const deleteUserFromEngagementQuery = useDeleteUserFromClient();
    const [deleteInProgress, setDeleteInProgress, deleteInProgressRef] = useStateRef<Set<string>>(new Set());
    const { confirm, ConfirmDialog } = useConfirm({
        title: "Remove User?",
        prompt: "Are you sure you want to remove this user from the client?",
    });
    const deleteUser = async (userId: string) => {
        const result = await confirm();
        if (result) {
            try {
                setDeleteInProgress(new Set([...deleteInProgressRef.current, userId]));
                await deleteUserFromEngagementQuery.mutateAsync({ clientId, userId });
                toast({ title: "Success", description: "User removed from engagement", status: "success", duration: 5000, isClosable: true });
            } finally {
                setDeleteInProgress(new Set([...deleteInProgressRef.current].filter(id => id !== userId)));
            }
        }
    };

    // ---- Setup Role Editing ----
    const [userRoleMap, setUserRoleMap, userRoleMapRef] = useStateRef<{ [userId: string]: RoleContainer }>({});
    type RoleValue = { old: number; new: number };
    type RoleContainer = {
        roleValue: RoleValue;
    };

    const toggleEditUserRole = (userId: string, roleId: number) => {
        const map = { ...userRoleMapRef.current };
        if (map[userId]) {
            delete map[userId];
        } else {
            map[userId] = { roleValue: { old: roleId, new: roleId } };
        }
        setUserRoleMap(map);
    };
    const onRoleChanged = (userId: string, roleId: number) => {
        const map = { ...userRoleMapRef.current };
        map[userId] = { ...map[userId], roleValue: { ...map[userId].roleValue, new: roleId } };
        setUserRoleMap(map);
    };
    const saveUserRoles = async () => {
        const userRolesContainer = Object.entries(userRoleMapRef.current)
            .filter(([, roleContainer]) => roleContainer.roleValue.old !== roleContainer.roleValue.new)
            .map(([userId, roleValue]) => ({
                userId: userId,
                roleId: roleValue.roleValue.new,
            }));

        await addOrUpdateUserOnClientQuery.mutateAsync({ clientId, userRoles: userRolesContainer });
        setUserRoleMap({});
        toast({
            title: "Success",
            description: "User roles updated.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };
    const revert = () => {
        setUserRoleMap({});
    };

    const csvFileGenerator = function (userData: Array<Record<string, string>>) {
        const csvRows: string[] = [];
        const headers = Object.keys(userData[0]);
        csvRows.push(headers.join(","));

        for (const row of userData) {
            const values = headers.map(e => {
                return row[e];
            });
            csvRows.push(values.join(","));
        }
        return csvRows.join("\n");
    };

    const downloadData = async function () {
        if (usersQuery != undefined) {
            const userData = usersQuery.data?.map(set => ({
                Name: set.user.firstName + " " + set.user.lastName,
                EmailId: set.user.email,
                Role: set.role.name,
            }));

            const data = csvFileGenerator(userData as Array<Record<string, string>>);

            return new Blob([data], { type: "text/csv" });
        } else {
            return new Blob(["No data"], { type: "text/csv" });
        }
    };
    const allRoles = useMemo(() => rolesQuery.data ?? [], [rolesQuery.data]);
    const clientRoles = useMemo(() => allRoles.filter(role => !role.isInternal) ?? [], [allRoles]);
    const columnHelper = createColumnHelper<ClientUser>();
    const initialState: InitialTableState = useMemo(() => {
        return {
            sorting: [{ id: "user", desc: false }],
        };
    }, []);

    return (
        <Page title="Manage Users">
            <Flex flexDirection={"column"} gap={"2rem"} minHeight={"100%"}>
                <Card>
                    <CardHeader bgGradient="linear(to-br, blue.500, blue.300)"></CardHeader>
                    <CardBody>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                addUsers();
                            }}
                        >
                            <Flex mb={"1rem"} gap={"2rem"} alignItems={"flex-end"}>
                                <Box flexGrow={1}>
                                    <FormControl>
                                        <FormLabel>Add Users:</FormLabel>
                                        <AutoCompleteMulti
                                            loadOptions={searchUsers}
                                            value={userAutoCompleteOptions}
                                            onChange={onUserSelected}
                                            placeholder="Type the name or email of the user to add"
                                            noOptionsMessage={(obj: { inputValue: string }) =>
                                                obj.inputValue.length > 2 ? "No Users Found." : "Type at least 2 characters to initiate user search."
                                            }
                                            loadingMessage={() => "Searching for Users..."}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <Button
                                        type="submit"
                                        leftIcon={<IconPlus />}
                                        px={"1rem"}
                                        isDisabled={!userAutoCompleteOptions.length}
                                        isLoading={!!userAutoCompleteOptions.length && addOrUpdateUserOnClientQuery.isPending}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Flex>
                        </form>
                    </CardBody>
                </Card>

                <Flex
                    as={"form"}
                    onSubmit={e => {
                        e.preventDefault();
                        saveUserRoles();
                    }}
                    grow={1}
                    flexDirection={"column"}
                >
                    <Flex>
                        <Spacer />
                        <DownloadButton
                            fontWeight={"normal"}
                            variant={"naked"}
                            bottom={"1rem"}
                            leftIcon={<IconDownload />}
                            downloadFn={async progressCallback => {
                                const blob = await downloadData();
                                return { blob, fileName: "ClientUsersList_" + clientId + ".csv" };
                            }}
                        >
                            Export User List
                        </DownloadButton>
                    </Flex>

                    <Box position={"relative"}>
                        <Box
                            as={ScaleFade}
                            initialScale={0.1}
                            in={usersQuery.isFetching || rolesQuery.isFetching}
                            delay={0.2}
                            position={"absolute"}
                            top={0}
                            right={0}
                            zIndex={1}
                            py={".5rem"}
                            pr={"1rem"}
                        >
                            <ScaleLoader color="var(--chakra-colors-blue-500)" />
                        </Box>
                        <Card>
                            <CardBody overflowX={"auto"}>
                                <DataTable
                                    tableProps={{ size: "sm" }}
                                    data={usersQuery.data || []}
                                    initialState={initialState}
                                    columns={[
                                        columnHelper.accessor(row => `${row.user.firstName} ${row.user.lastName}`, { id: "user", header: "User" }),
                                        columnHelper.accessor("user.email", { header: "Email" }),
                                        columnHelper.accessor("role.name", {
                                            header: "Role",
                                            cell: ({
                                                row: {
                                                    original: { user, role },
                                                },
                                            }) =>
                                                userRoleMap[user.id] ? (
                                                    <Shimmer isLoading={rolesQuery.isLoading}>
                                                        <Select
                                                            value={userRoleMap[user.id].roleValue.new}
                                                            onChange={event => {
                                                                onRoleChanged(user.id, parseInt(event.target.value));
                                                            }}
                                                        >
                                                            <option key={0} value={0}>
                                                                No Role Assigned
                                                            </option>
                                                            {(user.isInternal ? allRoles : clientRoles).map(role => (
                                                                <option key={role.id} value={role.id}>
                                                                    {role.name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </Shimmer>
                                                ) : role ? (
                                                    <Badge colorScheme={"orange"}>{role.name}</Badge>
                                                ) : null,
                                        }),
                                        columnHelper.accessor(row => `${row?.createdBy?.firstName} ${row?.createdBy?.lastName}`, { id: "createdBy", header: "Added By" }),
                                        columnHelper.accessor("createdOn", {
                                            header: "Added On",
                                            cell: ({ row: { original } }) => formatTimestampStr(original?.createdOn?.toString(), { appendTime: false }),
                                        }),
                                        columnHelper.accessor("lastAccessedOn", {
                                            header: "Last Accessed",
                                            cell: ({ row: { original } }) => formatTimestampStr(original?.lastAccessedOn?.toString(), { appendTime: false }),
                                        }),
                                        {
                                            id: "actions",
                                            header: "",
                                            enableColumnFilter: false,
                                            enableSorting: false,
                                            cell: ({
                                                row: {
                                                    original: { user, role },
                                                },
                                            }) => (
                                                <Box display={"flex"} gap={"1rem"} alignItems={"center"} justifyContent={"flex-end"} height={"100%"}>
                                                    <IconButton
                                                        icon={userRoleMap[user.id] ? <IconEditOff /> : <IconEdit />}
                                                        aria-label={"edit"}
                                                        variant={"naked"}
                                                        onClick={() => {
                                                            toggleEditUserRole(user.id, role.id);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon={<IconTrash />}
                                                        aria-label={"delete"}
                                                        variant={"naked"}
                                                        onClick={() => {
                                                            deleteUser(user.id);
                                                        }}
                                                        isLoading={deleteInProgress.has(user.id)}
                                                    />
                                                </Box>
                                            ),
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Box>

                    <Collapse in={Object.keys(userRoleMap).length > 0}>
                        <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                            <Button
                                type="submit"
                                variant={"primary"}
                                mr={3}
                                isLoading={addOrUpdateUserOnClientQuery.isPending && Object.keys(userRoleMap).length > 0}
                            >
                                Save Changes
                            </Button>
                            <Button variant={"ghost"} onClick={revert}>
                                Cancel
                            </Button>
                        </Flex>
                    </Collapse>
                </Flex>

                <ConfirmDialog />
            </Flex>
        </Page>
    );
};
