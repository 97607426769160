import { FC } from "react";

import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { Page } from "../Page";
import { ClientRequest, useCreateClientQuery } from "../../api";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "am-tax-fe-core";

export const AddClientPage: FC = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const createClientQuery = useCreateClientQuery();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ClientRequest>();

    const onSubmit: SubmitHandler<ClientRequest> = async data => {
        await createClientQuery.mutateAsync(data);
        toast({ status: "success", title: "Client created.", description: "Client created successfully.", duration: 3000 });
        navigate("..");
    };

    return (
        <Page
            title="Add Client"
            gutter={
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/admin">Admin Dashboard</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink>Add Client</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={createClientQuery.isPending}>
                    <VStack spacing="1rem" alignItems="stretch" flexBasis={"50%"}>
                        <FormControl isInvalid={!!errors?.clientName}>
                            <FormLabel>Title:</FormLabel>
                            <Input {...register("clientName", { required: "Client name is required." })} />
                            <FormErrorMessage>{errors?.clientName?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors?.description}>
                            <FormLabel>Description:</FormLabel>
                            <Input {...register("description", { required: "Description is required." })} />
                            <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors?.externalId}>
                            <FormLabel>External ID:</FormLabel>
                            <Input {...register("externalId", { required: "External ID is required." })} />
                            <FormErrorMessage>{errors?.externalId?.message}</FormErrorMessage>
                        </FormControl>
                        <Flex justifyContent={"end"}>
                            <Box>
                                <Button type="submit" variant={"primary"}>
                                    Create Client
                                </Button>
                            </Box>
                        </Flex>
                    </VStack>
                </fieldset>
            </form>
        </Page>
    );
};
