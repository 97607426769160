import { FC, PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type ColumnsProps = PropsWithChildren<
    {
        columnCount: number;
        columnGap: string;
    } & BoxProps
>;

/**
 * Spreads the content across multiple columns
 * @param count
 * @param gap
 * @param children
 * @param boxProps
 * @constructor
 */
export const Columns: FC<ColumnsProps> = ({ columnCount, columnGap, children, ...boxProps }) => {
    return (
        <Box width={"100%"} sx={{ columnCount, columnGap }} {...boxProps}>
            {children}
        </Box>
    );
};
