import { FunctionComponent, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Tab, TabList, Tabs } from "@chakra-ui/react";
import { displayImportType, ImportType } from "../../enums";
import { paramToNum } from "am-tax-fe-core";
import { ClientImportDataViewer } from "../../components/ClientImportDataViewer";
import { ClientPageTemplate } from "./ClientPageTemplate";

const importTypes = [ImportType.Entity, ImportType.Partner, ImportType.FederalK1Lines, ImportType.StateActivation];

export const ClientSummaryPage: FunctionComponent = () => {
    const navigate = useNavigate();

    const { clientId, importType: importTypeParam } = useParams();
    console.log(importTypeParam);
    const importType = paramToNum(importTypeParam) as ImportType;

    const selectedTab = useMemo(() => importTypes.findIndex(i => i === importType), [importType]);

    const tabChanged = (index: number) => {
        navigate(`../clientSummary/${importTypes[index]}`);
    };

    useEffect(() => {
        if ((selectedTab === undefined || selectedTab < 0) && importTypes?.length) {
            navigate(`../clientSummary/${importTypes[0]}`, { replace: true });
        }
    }, [navigate, selectedTab]);

    if (!clientId || !importType) {
        return "";
    }

    return (
        <ClientPageTemplate
            title={"Client Summary"}
            clientId={clientId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/dataImports`);
            }}
        >
            <Tabs flexGrow={1} index={selectedTab} variant={"solid-rounded"} color={"cherry.400"} isFitted onChange={tabChanged}>
                <TabList>
                    {importTypes.map((i, index) => (
                        <Tab key={index}>{displayImportType(i)}</Tab>
                    ))}
                </TabList>
            </Tabs>
            <Box mt={"2rem"}>
                <ClientImportDataViewer clientId={clientId} importType={importType} />
            </Box>
        </ClientPageTemplate>
    );
};
