import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { CalculationInputsGrid } from "./CalculationInputsGrid";
import { CalculationHistoryGrid } from "./CalculationHistoryGrid";
import { useEntityPartners, useRoundingPartnerDetails, useTriggerCalculation } from "../../api";
import { SingleSelectControlled, useModalForResult, useToast } from "am-tax-fe-core";
import { SelectChildEntitiesForm } from "./SelectChildEntitiesForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { EntityPageTemplate } from "../EntityDataImportsPage/EntityPageTemplate";
import { IconArrowBack, IconAsterisk } from "@tabler/icons-react";

type Form = {
    partnerId?: string;
};

export const CalculationsPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const { clientId, entityId } = useParams();
    const roundingPartnerDetailsQuery = useRoundingPartnerDetails({ clientId, entityId });
    const roundingDetails = roundingPartnerDetailsQuery.data;

    const triggerCalculation = useTriggerCalculation();
    const { ModalForResult, openModalForResult } = useModalForResult(SelectChildEntitiesForm, { modalProps: { size: "2xl" } });

    const partnersQuery = useEntityPartners({ clientId, entityId });
    const partners = partnersQuery.data;

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<Form>({
        values: { partnerId: roundingDetails?.roundingPartnerOverrideId || roundingDetails?.roundingPartnerId },
    });

    const onSubmit: SubmitHandler<Form> = async data => {
        const { childEntityIds } = await openModalForResult({ clientId: clientId ?? "", entityId: entityId ?? "" });
        await triggerCalculation.mutateAsync({
            clientId: clientId ?? "",
            entityId: entityId ?? "",
            entityIds: childEntityIds,
            roundingPartnerOverrideId: data.partnerId!,
        });
        toast({
            title: "Calculation Initiated",
            description: "The calculation was added to the queue, and is being processed.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    if (!clientId || !entityId) {
        return "This page requires clientId and entityId to render.";
    }

    return (
        <EntityPageTemplate
            title={"Calculations"}
            clientId={clientId}
            entityId={entityId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/calculations`);
            }}
        >
            <ModalForResult />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex direction={"column"} gap="3rem">
                    <Card bg={"blue.25"}>
                        <CardBody p={"2rem 3rem 3rem 3rem"}>
                            <Grid gridTemplateColumns={"2fr 1fr"} alignItems={"center"} gap={"2rem"}>
                                <GridItem>
                                    <Heading size={"md"} m={0}>
                                        Inputs Ready for Calculation
                                    </Heading>
                                </GridItem>
                                <GridItem></GridItem>
                                <GridItem>
                                    <VStack align={"start"}>
                                        <FormControl isInvalid={!!errors?.partnerId}>
                                            <HStack>
                                                <FormLabel m={0}>Rounding Partner:</FormLabel>
                                                <SingleSelectControlled
                                                    control={control}
                                                    placeholder={"Select Rounding Partner"}
                                                    name={"partnerId"}
                                                    rules={{ required: "Rounding partner is required" }}
                                                    options={
                                                        partners?.map(p =>
                                                            p.id === roundingDetails?.roundingPartnerId
                                                                ? {
                                                                      before: <Icon as={IconAsterisk} color={"yellow.600"} />,
                                                                      value: p.id,
                                                                      label: `${p.partnerNumber} | ${p.firstName} ${p.lastName}`,
                                                                      after: <Text variant={"info"}>default</Text>,
                                                                  }
                                                                : {
                                                                      value: p.id,
                                                                      label: `${p.partnerNumber} | ${p.firstName} ${p.lastName}`,
                                                                  },
                                                        ) ?? []
                                                    }
                                                />
                                                {watch("partnerId") !== roundingDetails?.roundingPartnerId && (
                                                    <Tooltip hasArrow label="Reset back to default rounding partner" color={"whiteAlpha.900"} bg="yellow.600">
                                                        <IconButton
                                                            icon={<Icon as={IconArrowBack} boxSize={"1.5rem"} color={"yellow.600"} />}
                                                            aria-label={"restore default"}
                                                            variant={"naked"}
                                                            onClick={() => {
                                                                setValue("partnerId", roundingDetails?.roundingPartnerId);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </HStack>
                                            <FormErrorMessage>{errors?.partnerId?.message}</FormErrorMessage>
                                        </FormControl>
                                    </VStack>
                                </GridItem>
                                <GridItem></GridItem>
                                <CalculationInputsGrid clientId={clientId} entityId={entityId} container={{ backgroundColor: "white" }} />
                                <GridItem justifySelf={"center"}>
                                    <Button type={"submit"} variant={"primary"} isLoading={triggerCalculation.isPending}>
                                        Run Calculation
                                    </Button>
                                </GridItem>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Box>
                        <Heading fontSize={"md"}>Calculation Runs</Heading>
                        <CalculationHistoryGrid clientId={clientId} entityId={entityId} />
                    </Box>
                </Flex>
            </form>
        </EntityPageTemplate>
    );
};
