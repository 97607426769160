import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useEntities } from "../../api";
import { AgGrid, AgGridProps, formatTimestampStr } from "am-tax-fe-core";
import { ColDef, RowClickedEvent } from "ag-grid-community";

export const EntityGrid: FC<{ clientId: string } & AgGridProps> = ({ clientId, ...agGridProps }) => {
    const navigate = useNavigate();

    const entitiesQuery = useEntities(clientId);

    return (
        <AgGrid
            rowData={entitiesQuery.data}
            onRowClicked={({ data: entity }: RowClickedEvent) => navigate(`../entity/${entity?.id}/dataImports`)}
            columnDefs={
                [
                    {
                        headerName: "Entity Name",
                        colId: "name",
                        field: "name1",
                        sort: "asc",
                        valueGetter: ({ data: entity }) => `${entity?.name1} ${entity?.name2} ${entity?.name3}`,
                    },
                    { field: "entityIdentification" },
                    {
                        headerName: "EIN",
                        field: "ein",
                    },
                    {
                        headerName: "Last Updated",
                        field: "lastModified",
                        valueGetter: ({ data: entity }) => formatTimestampStr(entity?.lastModified.toString()),
                    },
                    {
                        headerName: "Last Updated By",
                        field: "lastModifiedBy",
                        valueGetter: ({ data: entity }) => `${entity?.lastModifiedBy?.firstName} ${entity?.lastModifiedBy?.lastName}`,
                    },
                    {
                        headerName: "Last Calculated",
                        field: "lastCalculated",
                        valueGetter: ({ data: entity }) => formatTimestampStr(entity?.lastCalculated?.toString()),
                    },
                    {
                        headerName: "Last Calculated By",
                        field: "lastCalculatedBy",
                        valueGetter: ({ data: entity }) => `${entity?.lastCalculatedBy?.firstName || ""} ${entity?.lastCalculatedBy?.lastName || ""}`,
                    },
                ] as ColDef[]
            }
            {...agGridProps}
        />
    );
};
