import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import {
    deleteImportSummary,
    DeleteImportSummaryArgs,
    deleteK1Package,
    DeleteK1PackageArgs,
    deleteReport,
    DeleteReportArgs,
    getCalculationRunsForImmediateChildren,
    GetCalculationRunsForImmediateChildrenArgs,
    getClient,
    getClientImportSummaries,
    GetClientImportSummariesArgs,
    getClients,
    getEntities,
    getEntity,
    GetEntityArgs,
    getEntityCalculationSummary,
    GetEntityCalculationSummaryArgs,
    getEntityHierarchy,
    GetEntityHierarchyArgs,
    getEntityIncome,
    GetEntityIncomeArgs,
    getEntityKPIs,
    GetEntityKPIsArgs,
    getEntitySummary,
    GetEntitySummaryArgs,
    getImportSummaries,
    GetImportSummariesArgs,
    getImportSummary,
    GetImportSummaryArgs,
    getK1PackageHistory,
    GetK1PackageHistoryArgs,
    GetEntityPartnerArgs,
    getEntityPartners,
    getEntityReportCategory,
    GetEntityReportCategoryArgs,
    getEntityReportHistory,
    GetEntityReportHistoryArgs,
    getWorksheetDataByImportSummaryId,
    GetWorksheetDataArgs,
    performImportAction,
    PerformImportActionArgs,
    triggerCalculation,
    TriggerCalculationArgs,
    triggerK1Package,
    TriggerK1PackageArgs,
    triggerEntityReport,
    TriggerEntityReportArgs,
    uploadClientDataFile,
    UploadClientDataFileArgs,
    uploadEntityDataFile,
    UploadEntityDataFileArgs,
    getClientDataByImportType,
    GetClientDataByImportTypeArgs,
    getEntityDataByImportType,
    GetEntityDataByImportTypeArgs,
    getClientConfiguration,
    GetClientConfigurationArgs,
    SaveClientConfigurationArgs,
    saveClientConfiguration,
    GetRoundingPartnerDetailsArgs,
    getRoundingPartnerDetails,
    GetClientReportHistoryArgs,
    getClientReportHistory,
    GetClientReportCategoryArgs,
    getClientReportCategory,
    TriggerClientReportArgs,
    triggerClientReport,
    GetClientPartnerArgs,
    getClientPartners,
    getRoles,
    SearchUsersArgs,
    User,
    searchUsers,
    getClientUsers,
    DeleteUserFromClientArgs,
    deleteUserFromClient,
    addOrUpdateUsersOnClient,
    AddUpdateUserOnClientArgs,
    PerformCalculationActionArgs,
    performCalculationAction,
    getCurrentUser,
    ClientRequest,
    createClient,
    GetClientKPIsArgs,
    getClientKPIs,
} from "./api";
import { errorMessage, mutationErrorHandler, useToast } from "am-tax-fe-core";
import { ImportAction, ImportStatus, ImportType, pollingInternval } from "../enums";

export function useClientKPIs(args: Partial<GetClientKPIsArgs>) {
    return useQuery({
        ...queryKeys.clients.clientKpis(args.clientId!),
        queryFn: () => getClientKPIs(args as GetClientKPIsArgs),
        enabled: !!args.clientId,
    });
}

export function useClients() {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.clients.all,
        queryFn: async () => {
            const clients = await getClients();
            clients.forEach(client => {
                queryClient.setQueryData(queryKeys.clients.byId(client.id).queryKey, client);
            });
            return clients;
        },
    });
}

export function useClient(clientId: string | undefined) {
    return useQuery({
        ...queryKeys.clients.byId(clientId || ""),
        queryFn: () => getClient(clientId || ""),
        enabled: !!clientId,
    });
}

export function useCreateClientQuery() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: ClientRequest) => {
            return await createClient(args);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.clients.all);
        },
        ...mutationErrorHandler(toast, "Unable to save client"),
    });
}

export function useClientConfiguration(clientId: string | undefined) {
    return useQuery({
        ...queryKeys.clientConfiguration.byId(clientId || ""),
        queryFn: () => getClientConfiguration({ clientId } as GetClientConfigurationArgs),
        enabled: !!clientId,
    });
}

export function useSaveClientConfiguration() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: SaveClientConfigurationArgs) => {
            return await saveClientConfiguration(args);
        },
        onSuccess: async (data, args) => {
            queryClient.setQueryData(queryKeys.clientConfiguration.byId(args.clientId).queryKey, data);
        },
        ...mutationErrorHandler(toast, "Unable to save configuration"),
    });
}

export function useEntities(clientId: string | undefined) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.entities.byClientId(clientId!),
        queryFn: async () => {
            const entities = await getEntities(clientId!);
            entities.forEach(entity => {
                queryClient.setQueryData(queryKeys.entities.byClientAndEntityId(clientId!, entity.id).queryKey, entity);
            });
            return entities;
        },
        enabled: !!clientId,
    });
}

export function useEntity(args: Partial<GetEntityArgs>) {
    return useQuery({
        ...queryKeys.entities.byClientAndEntityId(args.clientId!, args.entityId!),
        queryFn: () => getEntity(args as GetEntityArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useEntitySummary(args: Partial<GetEntitySummaryArgs>) {
    return useQuery({
        ...queryKeys.entities.entitySummary(args.clientId!, args.entityId!),
        queryFn: () => getEntitySummary(args as GetEntitySummaryArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useEntityKPIs(args: Partial<GetEntityKPIsArgs>) {
    return useQuery({
        ...queryKeys.entities.entityKpis(args.clientId!, args.entityId!),
        queryFn: () => getEntityKPIs(args as GetEntityKPIsArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useEntityHierarchy(args: Partial<GetEntityHierarchyArgs>) {
    return useQuery({
        ...queryKeys.entities.hierarchy(args.clientId!),
        queryFn: () => getEntityHierarchy(args as GetEntityHierarchyArgs),
        enabled: !!args.clientId,
    });
}

export function useEntityIncome(args: Partial<GetEntityIncomeArgs>) {
    return useQuery({
        ...queryKeys.entities.income(args.clientId!, args.entityId!),
        queryFn: () => getEntityIncome(args as GetEntityIncomeArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useImportSummaries(args: Partial<GetImportSummariesArgs>) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.entities.entityImportSummaries(args.clientId!, args.entityId!, args.importType!),
        queryFn: async () => {
            const importSummaries = await getImportSummaries(args as GetImportSummariesArgs);
            importSummaries.forEach(importSummary => {
                queryClient.setQueryData(queryKeys.entities.importSummaryById(args.clientId!, importSummary.id).queryKey, importSummary);
            });
            return importSummaries;
        },
        enabled: !!args.clientId && !!args.entityId && !!args.importType,
    });
}

export function useImportSummary(args: Partial<GetImportSummaryArgs>) {
    return useQuery({
        ...queryKeys.entities.importSummaryById(args.clientId!, args.importSummaryId!),
        queryFn: () => getImportSummary(args as GetImportSummaryArgs),

        enabled: !!args.clientId && !!args.importSummaryId,
    });
}

export function useDeleteImportSummary(entityId: string | undefined, importType: ImportType) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteImportSummaryArgs) => {
            return await deleteImportSummary(args);
        },

        onSuccess: async (data, args) => {
            if (entityId) {
                await queryClient.invalidateQueries(queryKeys.entities.entityImportSummaries(args.clientId, entityId, importType));
            } else {
                await queryClient.invalidateQueries(queryKeys.entities.clientImportSummaries(args.clientId, importType));
            }
            queryClient.removeQueries(queryKeys.entities.importSummaryById(args.clientId, args.importSummaryId));
        },
        ...mutationErrorHandler(toast, "Unable to delete"),
    });
}

export function useWorksheetDataByImportSummaryId(args: Partial<GetWorksheetDataArgs>) {
    return useQuery({
        ...queryKeys.worksheetData.byImportSummaryId(args.clientId!, args.importSummaryId!),
        queryFn: () => getWorksheetDataByImportSummaryId(args as GetWorksheetDataArgs),
        enabled: !!args.clientId && !!args.importSummaryId,
    });
}

export function useClientDataByImportType(args: Partial<GetClientDataByImportTypeArgs>) {
    return useQuery({
        ...queryKeys.worksheetData.byImportTypeForClient(args.clientId!, args.importType!),
        queryFn: () => getClientDataByImportType(args as GetClientDataByImportTypeArgs),
        enabled: !!args.clientId && !!args.importType,
    });
}

export function useEntityDataByImportType(args: Partial<GetEntityDataByImportTypeArgs>) {
    return useQuery({
        ...queryKeys.worksheetData.byImportTypeForEntity(args.clientId!, args.entityId!, args.importType!),
        queryFn: () => getEntityDataByImportType(args as GetEntityDataByImportTypeArgs),
        enabled: !!args.clientId && !!args.entityId && !!args.importType,
    });
}

export function useClientImportSummaries(args: Partial<GetClientImportSummariesArgs>) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.entities.clientImportSummaries(args.clientId!, args.importType!),
        queryFn: async () => {
            const response = await getClientImportSummaries(args as GetClientImportSummariesArgs);
            if (response.some(e => e.status === ImportStatus.Processing)) {
                setTimeout(async () => {
                    await queryClient.invalidateQueries(queryKeys.entities.clientImportSummaries(args.clientId!, args.importType!));
                }, pollingInternval);
            }
            return response;
        },
        enabled: !!args.clientId && !!args.importType,
    });
}

export function useUploadClientDataFile() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: UploadClientDataFileArgs) => {
            return await uploadClientDataFile(args);
        },
        onSettled: async (data, error, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.clientImportSummaries(args.clientId, args.importType));
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export function useUploadEntityDataFile() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: UploadEntityDataFileArgs) => {
            return await uploadEntityDataFile(args);
        },
        onSettled: async (data, error, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityImportSummaries(args.clientId, args.entityId, args.importType));
            await queryClient.invalidateQueries(queryKeys.worksheetData.byImportTypeForEntity(args.clientId, args.entityId, args.importType));
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export function usePerformImportAction(entityId?: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: PerformImportActionArgs) => {
            return await performImportAction(args);
        },
        onSuccess: async (data, args) => {
            // invalidate import summaries
            if (entityId) {
                await queryClient.invalidateQueries(queryKeys.entities.entityImportSummaries(args.clientId, entityId, data.type));
            } else {
                await queryClient.invalidateQueries(queryKeys.entities.clientImportSummaries(args.clientId, data.type));
            }

            // only invalidate data when action is submit
            if (args.importAction === ImportAction.Submit) {
                if (entityId) {
                    // For import actions performed at the ENTITY level
                    switch (data.type) {
                        case ImportType.PartnerInfo:
                            await queryClient.invalidateQueries(queryKeys.entities.entityKpis(args.clientId, entityId));
                            break;
                        case ImportType.FederalK1Input:
                            await queryClient.invalidateQueries(queryKeys.entities.income(args.clientId, entityId));
                            break;
                        case ImportType.SidePockets:
                            break;
                        case ImportType.SpecialAllocations:
                            break;
                        case ImportType.StateApportionment:
                            break;
                        case ImportType.StateK1Input:
                            break;
                        case ImportType.StateSpecialAllocations:
                            break;
                    }
                } else {
                    // For import actions performed at the CLIENT level
                    switch (data.type) {
                        case ImportType.Entity:
                            await queryClient.invalidateQueries(queryKeys.entities.hierarchy(args.clientId));
                            await queryClient.invalidateQueries(queryKeys.entities.byClientId(args.clientId));
                            await queryClient.invalidateQueries({ queryKey: [...queryKeys.entities.entityKpis._def, args.clientId] });
                            await queryClient.invalidateQueries({ queryKey: [...queryKeys.entities.byClientAndEntityId._def, args.clientId] });
                            break;
                        case ImportType.FederalK1Lines:
                            //TODO: Invalidate K1 Package History
                            break;
                        case ImportType.Partner:
                            await queryClient.invalidateQueries({ queryKey: [...queryKeys.entities.partners._def, args.clientId] });
                            break;
                    }
                }
            }
        },
        ...mutationErrorHandler(toast, "Unable to perform action"),
    });
}

export function usePerformCalculationAction(entityId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: PerformCalculationActionArgs) => {
            return await performCalculationAction(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityCalculationSummary(args.clientId, entityId));
            await queryClient.invalidateQueries(queryKeys.entities.entityCalculationRunsForImmediateChildren(args.clientId, entityId));
        },
        ...mutationErrorHandler(toast, "Unable to perform action"),
    });
}

export function useEntityCalculationSummary(args: Partial<GetEntityCalculationSummaryArgs>) {
    return useQuery({
        ...queryKeys.entities.entityCalculationSummary(args.clientId!, args.entityId!),
        queryFn: () => getEntityCalculationSummary(args as GetEntityCalculationSummaryArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useCalculationRunsForImmediateChildren(args: Partial<GetCalculationRunsForImmediateChildrenArgs>) {
    return useQuery({
        ...queryKeys.entities.entityCalculationRunsForImmediateChildren(args.clientId!, args.entityId!),
        queryFn: () => getCalculationRunsForImmediateChildren(args as GetCalculationRunsForImmediateChildrenArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useRoundingPartnerDetails(args: Partial<GetRoundingPartnerDetailsArgs>) {
    return useQuery({
        ...queryKeys.entities.roundingPartnerDetails(args.clientId!, args.entityId!),
        queryFn: () => getRoundingPartnerDetails(args as GetEntityCalculationSummaryArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useTriggerCalculation() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: TriggerCalculationArgs) => {
            return await triggerCalculation(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityCalculationSummary(args.clientId, args.entityId));
            await queryClient.invalidateQueries({ queryKey: [...queryKeys.entities.entityCalculationRunsForImmediateChildren._def, args.clientId] });
        },
        ...mutationErrorHandler(toast, "Unable to run calculation"),
    });
}

export function useClientReportHistory(args: Partial<GetClientReportHistoryArgs>) {
    return useQuery({
        ...queryKeys.clients.clientReportHistory(args.clientId!),
        queryFn: () => getClientReportHistory(args as GetClientReportHistoryArgs),
        enabled: !!args.clientId,
    });
}

export function useClientReportCategory(args: Partial<GetClientReportCategoryArgs>) {
    return useQuery({
        ...queryKeys.clients.clientReportCategory(args.clientId!),
        queryFn: () => getClientReportCategory(args as GetClientReportCategoryArgs),
        enabled: !!args.clientId,
    });
}

export function useTriggerClientReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: TriggerClientReportArgs) => {
            return await triggerClientReport(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.clients.clientReportHistory(args.clientId));
        },
        ...mutationErrorHandler(toast, "Unable to run report"),
    });
}

export function useClientDeleteReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteReportArgs) => {
            return await deleteReport(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.clients.clientReportCategory(args.clientId));
        },
        ...mutationErrorHandler(toast, "Unable to delete report"),
    });
}

export function useEntityReportHistory(args: Partial<GetEntityReportHistoryArgs>) {
    return useQuery({
        ...queryKeys.entities.entityReportHistory(args.clientId!, args.entityId!),
        queryFn: () => getEntityReportHistory(args as GetEntityReportHistoryArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useEntityReportCategory(args: Partial<GetEntityReportCategoryArgs>) {
    return useQuery({
        ...queryKeys.entities.entityReportCategory(args.clientId!, args.entityId!),
        queryFn: () => getEntityReportCategory(args as GetEntityReportCategoryArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useTriggerEntityReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: TriggerEntityReportArgs) => {
            return await triggerEntityReport(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityReportHistory(args.clientId, args.entityId));
        },
        ...mutationErrorHandler(toast, "Unable to run report"),
    });
}

export function useEntityDeleteReport(entityId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteReportArgs) => {
            return await deleteReport(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityReportHistory(args.clientId, entityId));
        },
        ...mutationErrorHandler(toast, "Unable to delete report"),
    });
}

export function useK1PackageHistory(args: Partial<GetK1PackageHistoryArgs>) {
    return useQuery({
        ...queryKeys.entities.entityK1PackageHistory(args.clientId!, args.entityId!),
        queryFn: () => getK1PackageHistory(args as GetK1PackageHistoryArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useClientPartners(args: Partial<GetClientPartnerArgs>) {
    return useQuery({
        ...queryKeys.clients.partners(args.clientId!),
        queryFn: () => getClientPartners(args as GetClientPartnerArgs),
        enabled: !!args.clientId,
    });
}

export function useEntityPartners(args: Partial<GetEntityPartnerArgs>) {
    return useQuery({
        ...queryKeys.entities.partners(args.clientId!, args.entityId!),
        queryFn: () => getEntityPartners(args as GetEntityPartnerArgs),
        enabled: !!args.clientId && !!args.entityId,
    });
}

export function useTriggerK1Package() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: TriggerK1PackageArgs) => {
            return await triggerK1Package(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityK1PackageHistory(args.clientId, args.entityId));
        },
        ...mutationErrorHandler(toast, "Unable to run report"),
    });
}

export function useDeleteK1Package(entityId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteK1PackageArgs) => {
            return await deleteK1Package(args);
        },
        onSuccess: async (data, args) => {
            await queryClient.invalidateQueries(queryKeys.entities.entityK1PackageHistory(args.clientId, entityId));
        },
        ...mutationErrorHandler(toast, "Unable to delete report"),
    });
}

//User Management
export function useCurrentUser() {
    return useQuery({ ...queryKeys.users.current, queryFn: getCurrentUser });
}

export function useRoles() {
    return useQuery({ ...queryKeys.roles.all, queryFn: getRoles, gcTime: Infinity, staleTime: Infinity, ...errorMessage("Unable to fetch roles") });
}

export function useSearchUsersQueryFn() {
    const queryClient = useQueryClient();

    return async function (args: SearchUsersArgs): Promise<User[]> {
        if (args.searchText && args.searchText.length > 1) {
            return queryClient.fetchQuery({ ...queryKeys.users.search(args), queryFn: () => searchUsers(args) });
        } else {
            return [];
        }
    };
}

export function useClientUsers(clientId: string | undefined) {
    return useQuery({
        ...queryKeys.users.byClientId(clientId!),
        queryFn: () => {
            return getClientUsers({ clientId: clientId! });
        },
        enabled: !!clientId,
    });
}

function invalidateClientUsers(queryClient: QueryClient, clientId: string) {
    return queryClient.invalidateQueries({ queryKey: [...queryKeys.users.byClientId._def, clientId] });
}

export function useDeleteUserFromClient() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteUserFromClientArgs) => {
            await deleteUserFromClient(args);
            return args;
        },

        onSuccess: async (response: DeleteUserFromClientArgs) => {
            return invalidateClientUsers(queryClient, response.clientId);
        },
        ...mutationErrorHandler(toast, "Unable to delete user from client"),
    });
}

export function useAddOrUpdateUsersOnClient() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: AddUpdateUserOnClientArgs) => {
            await addOrUpdateUsersOnClient(args);
            return args;
        },

        onSuccess: async (response: AddUpdateUserOnClientArgs) => {
            return invalidateClientUsers(queryClient, response.clientId);
        },
        ...mutationErrorHandler(toast, "Unable to add to client"),
    });
}
