import { FunctionComponent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EntityGrid } from "../../components/EntityGrid";
import { ClientPageTemplate } from "../ClientSummaryPage/ClientPageTemplate";

export const SelectEntityPage: FunctionComponent = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();

    if (!clientId) {
        return "This page requires clientId to render.";
    }

    return (
        <ClientPageTemplate
            title={"Select Entity"}
            clientId={clientId}
            nodeClicked={entityId => {
                navigate(`../entity/${entityId}/dataImports`);
            }}
        >
            <EntityGrid clientId={clientId} />
        </ClientPageTemplate>
    );
};
