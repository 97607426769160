import { FC } from "react";
import { Box, Card, CardBody, Center, Heading, VStack } from "@chakra-ui/react";
import { useEntityIncome } from "../../api";
import { MayHaveLabel, ResponsivePie } from "@nivo/pie";
import { theme } from "../../theme";

export const EntityIncomeCard: FC<{ clientId: string; entityId: string }> = ({ clientId, entityId }) => {
    const entityIncomeQuery = useEntityIncome({ clientId, entityId });
    const entityIncome = entityIncomeQuery.data || null;

    type ChartData = { id: string; value: number } & MayHaveLabel;

    const data: ChartData[] = [];
    if (entityIncome?.income || entityIncome?.flowThrough) {
        data.push({
            id: "Entity",
            value: entityIncome?.income || 0,
        });
        data.push({
            id: "Pass-Through",
            value: entityIncome?.flowThrough || 0,
        });
    }

    return (
        <Card variant={"withShadow"}>
            <CardBody>
                <VStack>
                    <Heading m={0} fontWeight={"bold"} textAlign={"center"}>
                        Entity vs Pass-Through Income
                    </Heading>
                    <Box h={150} w={"100%"}>
                        {!data.length ? (
                            <Center height={"100%"}>No Data</Center>
                        ) : (
                            <ResponsivePie
                                data={data}
                                arcLabel={d =>
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                    }).format(d.value)
                                }
                                startAngle={90}
                                endAngle={-270}
                                tooltip={() => null}
                                margin={{ top: 20, right: 100, bottom: 20, left: 100 }}
                                colors={[theme.colors.purple[500], theme.colors.gray[300]]}
                                cornerRadius={3}
                                arcLinkLabelsOffset={5}
                                activeOuterRadiusOffset={8}
                                arcLinkLabelsDiagonalLength={10}
                                arcLinkLabelsStraightLength={10}
                                arcLabelsTextColor={theme.colors.whiteAlpha[800]}
                            />
                        )}
                    </Box>
                </VStack>
            </CardBody>
        </Card>
    );
};
