import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Flex, Heading, Link, List, ListIcon, ListItem } from "@chakra-ui/react";
import { Page } from "../Page";
import { IconChevronRight } from "@tabler/icons-react";

export const AdminDashboardPage: FC = () => {
    return (
        <Page title="Admin Dashboard">
            <Flex direction={"column"} gap={"2rem"}>
                <Box>
                    <Heading>Manage Clients</Heading>
                    <List>
                        <ListItem>
                            <ListIcon as={IconChevronRight} />
                            <Link as={RouterLink} to="addClient">
                                Add Client
                            </Link>
                        </ListItem>
                    </List>
                </Box>
            </Flex>
        </Page>
    );
};
