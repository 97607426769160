import { FC, PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type ColumnItemProps = PropsWithChildren<BoxProps>;

export const ColumnItem: FC<ColumnItemProps> = ({ children, ...boxProps }) => {
    return (
        <Box sx={{ breakInside: "avoid" }} {...boxProps}>
            {children}
        </Box>
    );
};
