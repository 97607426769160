import { FC } from "react";
import { IconAlertTriangle, IconCheck, IconCircle, IconProgress, IconSquareAsteriskFilled, IconStarFilled } from "@tabler/icons-react";

export enum ImportStatus {
    NotStarted = 1,
    Processing = 2,
    Ready = 3,
    Complete = 4,
    Accepted = 5,
    Error = 6,
}

type ImportStatusDetails = { as: FC; color?: string; title: string };

export const ImportStatusDetailsLookup: Record<ImportStatus, ImportStatusDetails> = {
    [ImportStatus.NotStarted]: { as: IconSquareAsteriskFilled, title: "Not Started", color: "blue.500" },
    [ImportStatus.Processing]: { as: IconProgress, title: "Processing", color: "orange.500" },
    [ImportStatus.Ready]: { as: IconCircle, title: "Ready", color: "orange.500" },
    [ImportStatus.Complete]: { as: IconCheck, title: "Complete", color: "green.500" },
    [ImportStatus.Accepted]: { as: IconStarFilled, title: "Active", color: "blue.500" },
    [ImportStatus.Error]: { as: IconAlertTriangle, title: "Error", color: "red.500" },
};

export const displayImportStatus = (importStatus: ImportStatus) => {
    return ImportStatusDetailsLookup[importStatus].title;
};
