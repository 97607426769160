import { FunctionComponent } from "react";
import { Box, Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ImportTypeResponse } from "../../api";
import { displayImportType, ImportType } from "../../enums";
import { EntityImportDataViewer } from "../../components/EntityImportDataViewer";
import { EntityPageTemplate } from "../EntityDataImportsPage/EntityPageTemplate";
import { useImportTypeTabs } from "../EntityDataImportsPage/useImportTypeTabs";
import { displayTaxJurisdiction, taxJurisdictions } from "../../enums/TaxJurisdiction";

export const ReviewDataPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();

    const {
        entityId,
        taxJurisdiction,
        importType,
        importTypes,
        selectedTaxJurisdictionTab,
        taxJurisdictionTabChanged,
        selectedImportTypeTab,
        importTypeTabChanged,
    } = useImportTypeTabs("../reviewData");

    if (!clientId || !entityId) {
        return "This page requires clientId and entityId to render.";
    }

    return (
        <EntityPageTemplate
            title={"ReviewData"}
            clientId={clientId}
            entityId={entityId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/reviewData/${taxJurisdiction}/${importType}`);
            }}
            gutter={
                <Tabs index={selectedTaxJurisdictionTab} onChange={taxJurisdictionTabChanged} mb={".5rem"} size={"lg"}>
                    <TabList borderBottom={"1px solid"} borderColor={"gray.100"}>
                        {taxJurisdictions.map((jurisdiction, index) => (
                            <Tab key={index} _selected={{ color: "blue.800", fontWeight: 600 }} color={"gray.400"} fontWeight={300} px={"1.5rem"}>
                                {displayTaxJurisdiction(jurisdiction)}
                            </Tab>
                        ))}
                    </TabList>
                    <TabIndicator mt="-2.5px" height="3px" bg="blue.500" borderRadius="1px" />
                </Tabs>
            }
        >
            <Tabs index={selectedImportTypeTab} variant={"solid-rounded"} isFitted onChange={importTypeTabChanged}>
                <TabList>
                    {importTypes.map((importTypeResponse: ImportTypeResponse, index) => (
                        <Tab key={index}>{displayImportType(importTypeResponse.type as ImportType)}</Tab>
                    ))}
                </TabList>
            </Tabs>
            <Box mt={"3rem"}>
                <EntityImportDataViewer clientId={clientId} entityId={entityId} importType={importType} />
            </Box>
        </EntityPageTemplate>
    );
};
