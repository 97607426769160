import { atom } from "jotai";
import { ImportType } from "../enums";
import { TaxJurisdiction } from "../enums/TaxJurisdiction";
import { getIsHierarchyTaxiLinesPref } from "../utils/localStorageUtils";

export const showClientSummaryAtom = atom<boolean>(true);
export const showEntitySummaryAtom = atom<boolean>(true);
export const isHierarchyTaxiLinesAtom = atom<boolean>(getIsHierarchyTaxiLinesPref());

type TabState = {
    taxJurisdiction: TaxJurisdiction;
    importTypeByJurisdiction: Record<number, ImportType>;
};

export const entityImportTabs = atom<TabState>({
    taxJurisdiction: TaxJurisdiction.Federal,
    importTypeByJurisdiction: { [TaxJurisdiction.Federal]: ImportType.PartnerInfo },
});
