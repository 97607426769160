import { FC } from "react";
import { Box, Button, Card, CardBody, Grid, Heading, HStack, Icon, StackProps, Text, VStack } from "@chakra-ui/react";
import { DownloadButton, DownloadFunction, FileContainer, FileUploader, useToast } from "am-tax-fe-core";
import { IconChevronCompactRight, IconDownload, IconUpload } from "@tabler/icons-react";

type ImportSummaryControlsProps = {
    hasData: boolean;
    downloadTemplateFn: DownloadFunction;
    downloadDataFn: DownloadFunction;
    onFileAdded: (fileContainer: FileContainer) => Promise<void>;
} & StackProps;

export const DataImportControls: FC<ImportSummaryControlsProps> = ({ hasData, downloadTemplateFn, downloadDataFn, onFileAdded, ...stackProps }) => {
    const toast = useToast();

    return (
        <VStack align={"center"} spacing={"2.5rem"} {...stackProps}>
            <Grid
                gap={{ base: "2rem", xl: "1rem", "2xl": "2rem" }}
                gridTemplateColumns={{ base: "auto auto", xl: "auto 1fr auto auto 1fr" }}
                alignItems={"center"}
            >
                <Box>
                    <Heading whiteSpace={"nowrap"} mb={".5rem"}>
                        Step 1
                    </Heading>
                    <Text variant={"info"} whiteSpace={"nowrap"}>
                        Download &amp; Edit
                    </Text>
                </Box>
                <Card minWidth={"20rem"} h={"8rem"}>
                    <CardBody p={".5rem"}>
                        <HStack h={"100%"}>
                            <Box>
                                <Icon as={IconDownload} boxSize={"4rem"} color={"orange.300"} />
                            </Box>
                            <Box>
                                <DownloadButton
                                    variant={"ghost"}
                                    fontWeight={"normal"}
                                    downloadFn={async progressCallback => {
                                        try {
                                            if (hasData || !hasData) {
                                                return await downloadDataFn(progressCallback);
                                            } else {
                                                return await downloadTemplateFn(progressCallback);
                                            }
                                        } catch (e) {
                                            toast({
                                                title: "Download Failed",
                                                description: "The file could not be downloaded",
                                                status: "error",
                                                duration: 5000,
                                                isClosable: true,
                                            });
                                            throw e;
                                        }
                                    }}
                                >
                                    Download {hasData || !hasData ? "Latest Snapshot" : "Blank Template"}
                                </DownloadButton>
                            </Box>
                        </HStack>
                    </CardBody>
                </Card>
                <Box display={{ base: "none", xl: "block" }}>
                    <Icon as={IconChevronCompactRight} boxSize={"6rem"} color={"gray.200"} />
                </Box>
                <Box>
                    <Heading whiteSpace={"nowrap"} mb={".5rem"}>
                        Step 2
                    </Heading>
                    <Text variant={"info"} whiteSpace={"nowrap"}>
                        Upload Changes
                    </Text>
                </Box>

                <FileUploader
                    containerProps={{ cursor: "pointer", p: ".5rem", minWidth: "22rem", h: "8rem" }}
                    promptContent={
                        <HStack>
                            <Box>
                                <Icon as={IconUpload} boxSize={"4rem"} color={"orange.300"} />
                            </Box>
                            <Button variant={"ghost"} fontWeight={"normal"}>
                                Drag file here or click to select it
                            </Button>
                        </HStack>
                    }
                    onFileAdded={async (fileContainer: FileContainer) => {
                        await onFileAdded(fileContainer);
                        toast({
                            title: "File Uploaded",
                            description: "The file was uploaded successfully",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                    }}
                    accept={{
                        "application/vnd.ms-excel": ["xls"],
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ["xlsx"],
                        "text/csv": ["csv"],
                    }}
                />
            </Grid>

            <Heading fontSize={"md"} alignSelf={"start"}>
                Previous Uploads
            </Heading>
        </VStack>
    );
};
