import { FunctionComponent } from "react";
import { ClientPageTemplate } from "../ClientSummaryPage/ClientPageTemplate";
import { useParams, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { displayRoundingPartnerRule, RoundingPartnerRule, RoundingPartners } from "../../enums";
import { Button, useToast } from "am-tax-fe-core";
import { Box, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, VStack } from "@chakra-ui/react";
import { ColumnItem, Columns } from "../../components/Columns";
import { useClientConfiguration, useSaveClientConfiguration } from "../../api";

type Form = {
    roundingPartnerRule: string;
};

export const ClientConfigurationPage: FunctionComponent = () => {
    const toast = useToast();
    const { clientId } = useParams();
    const clientConfigurationQuery = useClientConfiguration(clientId);
    const saveConfigurationQuery = useSaveClientConfiguration();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Form>({ values: { roundingPartnerRule: String(clientConfigurationQuery.data?.roundingPartnerRule) } });

    const onSubmit: SubmitHandler<Form> = async data => {
        await saveConfigurationQuery.mutateAsync({ clientId: clientId!, roundingPartnerRule: Number(data.roundingPartnerRule) as RoundingPartnerRule });
        toast({ title: "Success", description: "Configuration Saved", status: "success", duration: 3000 });
    };

    if (!clientId) {
        return "This page requires clientId and render.";
    }

    return (
            <ClientPageTemplate
                title={"Configuration"}
            clientId={clientId}
            nodeClicked={newEntityId => {
                navigate(`/client/${clientId}/entity/${newEntityId}/dataImports`);
                }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={"2rem"} align={"start"}>
                    <Columns columnCount={3} columnGap={"3rem"}>
                        <ColumnItem marginBottom={"2rem"}>
                            <FormControl isInvalid={!!errors?.roundingPartnerRule}>
                                <FormLabel>Rounding Partner Default</FormLabel>
                                <Controller
                                    name="roundingPartnerRule"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup onChange={field.onChange} value={field.value}>
                                            <VStack align={"start"}>
                                                {RoundingPartners.map(rule => (
                                                    <Radio key={rule} value={String(rule)}>
                                                        {displayRoundingPartnerRule(rule)}
                                                    </Radio>
                                                ))}
                                            </VStack>
                                        </RadioGroup>
                                    )}
                                />
                                <FormErrorMessage>{errors?.roundingPartnerRule?.message}</FormErrorMessage>
                            </FormControl>
                        </ColumnItem>
                    </Columns>

                    <Box alignSelf={"start"}>
                        <Button type={"submit"} variant={"primary"} isLoading={saveConfigurationQuery.isPending}>
                            Save
                        </Button>
                    </Box>
                </VStack>
            </form>
        </ClientPageTemplate>
    );
};
